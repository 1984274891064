export const jsonTransformMenuRoleLevel = (groupArr) => {
  const transformedArray = groupArr.map((group) => ({
    label: group.MenuName,
    value: group.MenuName,
    id: group.MenuId,
    children: group.Children,
  }));

  return transformedArray;
};
