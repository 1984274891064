export const jsonAllLevel1 = (levels) => {
  const transformedArray = levels.map((user) => user.member_hrchy_l1);
  const uniqueValuesArray = [...new Set(transformedArray)];
  return uniqueValuesArray;
};

export const jsonAllLevel2 = (levels) => {
  const transformedArray = levels.map((user) => user.member_hrchy_l2);
  const uniqueValuesArray = [...new Set(transformedArray)];
  return uniqueValuesArray;
};

export const jsonAllLevel3 = (levels) => {
  const transformedArray = levels.map((user) => user.member_hrchy_l3);
  const uniqueValuesArray = [...new Set(transformedArray)];
  return uniqueValuesArray;
};

export const jsonAllLevel4 = (levels) => {
  const transformedArray = levels.map((user) => user.member_hrchy_l4);
  const uniqueValuesArray = [...new Set(transformedArray)];
  return uniqueValuesArray;
};

export const jsonAllLevel5 = (levels) => {
  const transformedArray = levels.map((user) => user.member_hrchy_l5);
  const uniqueValuesArray = [...new Set(transformedArray)];
  return uniqueValuesArray;
};
