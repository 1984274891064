import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Select,
  MenuItem,
  Button,
  Typography,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { currentUserData, selectBiLogin } from "../../user/LoginSlice";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { primaryColor1, whiteColor } from "../../commonStyles/styles";
import { routeName } from "../../commonStrings/CommonStrings";
import { jsonTransform } from "../helpers/UserListHelper";
import { jsonTransformGroup } from "../helpers/GroupNameHelper";
import { jsonTransformCustomerCode } from "../helpers/CustomerCodeHelper";
import { nullHandlerHelper } from "../helpers/nullHandlerHelper";
import axios from "axios";
import {
  jsonTransformLevel1,
  jsonTransformLevel2,
  jsonTransformLevel3,
  jsonTransformLevel4,
  jsonTransformLevel5,
} from "../helpers/levelsHelper";

import {
  jsonAllLevel1,
  jsonAllLevel2,
  jsonAllLevel3,
  jsonAllLevel4,
  jsonAllLevel5,
} from "../helpers/allLevelsHelper";
import { DataGrid } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "../../../assets/Delete.png";
import { currentUserList } from "../AdminSlice";
import "../loader.scss";

function AddRowLevelSecurityV2({ updateParentState, refreshTable }) {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    groupName: "",
    // emailId: "",
    customerCode: "",
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
  });

  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [customerCode, setCustomerCode] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [noRestrictions, setNoRestrictions] = useState("");

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const [level1Arr, setLevel1Arr] = useState([]);
  const [level2Arr, setLevel2Arr] = useState([]);
  const [level3Arr, setLevel3Arr] = useState([]);
  const [level4Arr, setLevel4Arr] = useState([]);
  const [level5Arr, setLevel5Arr] = useState([]);

  const [allL1Params, setAllL1Params] = useState([]);
  const [allL2Params, setAllL2Params] = useState([]);
  const [allL3Params, setAllL3Params] = useState([]);
  const [allL4Params, setAllL4Params] = useState([]);
  const [allL5Params, setAllL5Params] = useState([]);

  const [nextSLNumber, setNextSLNumber] = useState(1);
  const [savedRequests, setSavedRequests] = useState([]);
  const [tempDeleteId, setTempDeleteId] = useState("");
  const [existingDeleteId, setExistingDeleteId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [existingDeleteOpen, setExistingDeleteOpen] = useState(false);
  const [existingTable, setExistingTable] = useState([]);

  const [addLoader, setAddLoader] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const userListG = useSelector(currentUserList);

  const columns = [
    {
      field: "slNumber",
      headerName: "ID",
      headerClassName: "super-app-theme--header",

      hide: true,
      flex: 1,

      renderHeader: () => <strong>{"ID"}</strong>,
    },
    {
      field: "userName",
      headerName: "Username",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Username"}</strong>,
    },
    {
      field: "groupName",
      headerName: "Group Name",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Group Name"}</strong>,
    },
    {
      field: "customerCode",
      headerName: "Customer Code",
      headerClassName: "super-app-theme--header",
      width: 200,

      renderHeader: () => <strong>{"Customer Code"}</strong>,
    },
    {
      field: "level1All",
      headerName: "Level 1",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 1"}</strong>,
    },

    {
      field: "level2All",
      headerName: "Level 2",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 2"}</strong>,
    },
    {
      field: "level3All",
      headerName: "Level 3",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 3"}</strong>,
    },
    {
      field: "level4All",
      headerName: "Level 4",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 4"}</strong>,
    },

    {
      field: "level5All",
      headerName: "Level 5",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 5"}</strong>,
    },
    {
      field: "actions",
      headerName: "Actions",

      disableColumnMenu: true,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong>{"Actions"}</strong>,

      align: "left",
      flex: 1,
      renderCell: (params) => {
        const { id } = params;

        const handleDelete = () => {
          // Perform delete action based on the row data
          setTempDeleteId(id);
          //
          handleClickDeleteOpen();
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align icons vertically in the center
              gap: "10px", // Add spacing between the icons
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className="bg-color pointerCursor"
              onClick={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  const columnsExistingTable = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",

      hide: true,
      flex: 1,

      renderHeader: () => <strong>{"ID"}</strong>,
    },
    {
      field: "username",
      headerName: "Username",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Username"}</strong>,
    },
    {
      field: "groupname",
      headerName: "Group Name",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Group Name"}</strong>,
    },
    {
      field: "customer_code",
      headerName: "Customer Code",
      headerClassName: "super-app-theme--header",
      width: 200,

      renderHeader: () => <strong>{"Customer Code"}</strong>,
    },
    {
      field: "level1",
      headerName: "Level 1",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 1"}</strong>,
    },

    {
      field: "level2",
      headerName: "Level 2",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 2"}</strong>,
    },
    {
      field: "level3",
      headerName: "Level 3",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 3"}</strong>,
    },
    {
      field: "level4",
      headerName: "Level 4",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 4"}</strong>,
    },

    {
      field: "level5",
      headerName: "Level 5",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 5"}</strong>,
    },
    {
      field: "actions",
      headerName: "Actions",

      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong>{"Actions"}</strong>,

      align: "left",
      flex: 1,
      renderCell: (params) => {
        const { id } = params;

        const handleDelete = () => {
          // Perform delete action based on the row data
          setExistingDeleteId(id);
          //
          handleClickExistingDeleteOpen();
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align icons vertically in the center
              gap: "10px", // Add spacing between the icons
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className="bg-color pointerCursor"
              onClick={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  const handleClickDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleClickDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleClickExistingDeleteOpen = () => {
    setExistingDeleteOpen(true);
  };
  const handleClickExistingDeleteClose = () => {
    setExistingDeleteOpen(false);
  };

  const handleTempDeleteUser = () => {
    const filteredObject = savedRequests.filter(
      (obj) => obj.slNumber !== tempDeleteId
    );
    handleClickDeleteClose();
    setSavedRequests(filteredObject);
  };

  const handleExistingDeleteUser = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/security/row/deleteById`, {
        id: existingDeleteId,
      })
      .then((res) => {
        handleClickExistingDeleteClose();
        /**
         * !Make code optimization for this API
         */
        const reqBody = {
          userName: formData.userName === "" ? "null" : formData.userName,
          groupName: formData.groupName === "" ? "null" : formData.groupName,
          customerCode: formData.customerCode,
        };

        checkExistingTableData(reqBody);
        refreshTable();
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleInputChange = async (event, newValue, nameAuto) => {
    const { name, value } = event.target;

    if (
      name === "level1" ||
      name === "level2" ||
      name === "level3" ||
      name === "level4" ||
      name === "level5"
    ) {
      const selectedValues = Array.isArray(value) ? value : [value];
      setFormData({
        ...formData,
        [name]: selectedValues,
      });
    } else {
      if (
        nameAuto === "groupName" ||
        nameAuto === "userName" ||
        nameAuto === "customerCode"
      ) {
        setFormData({
          ...formData,
          [nameAuto]: newValue?.value,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }

    if (nameAuto === "groupName") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        userName: "", // Update the userName property
      }));

      const reqBody = {
        userName: "null",
        groupName: newValue.value,
      };
      checkExistingTableData(reqBody);
    }
    if (nameAuto === "userName") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        groupName: "", // Update the userName property
      }));
      const reqBody = {
        userName: newValue.value,
        groupName: "null",
      };
      checkExistingTableData(reqBody);
    }

    if (nameAuto === "customerCode" && newValue?.value !== "") {
      await getAllLevel1(newValue?.value);
      setIsChecked(false);
      setNoRestrictions("");
      setFormData((prevFormData) => ({
        ...prevFormData,
        level1: ["All"],
        level2: ["All"],
        level3: ["All"],
        level4: ["All"],
        level5: ["All"],
      }));
    }

    if (name === "level1" && value?.length > 0) {
      var temp1 = [];
      temp1.push(value);
      getAllLevel2(formData.customerCode, temp1);
      setFormData((prevFormData) => ({
        ...prevFormData,

        level2: [],
        level3: [],
        level4: [],
        level5: [],
      }));
    }

    if (name === "level2" && value?.length > 0) {
      var temp2 = [];
      temp2.push(value);
      getAllLevel3(formData.customerCode, formData.level1, temp2);
      setFormData((prevFormData) => ({
        ...prevFormData,

        level3: [],
        level4: [],
        level5: [],
      }));
    }

    if (name === "level3" && value?.length > 0) {
      var temp3 = [];
      temp3.push(value);
      getAllLevel4(
        formData.customerCode,
        formData.level1,
        formData.level2,
        temp3
      );
      setFormData((prevFormData) => ({
        ...prevFormData,

        level4: [],
        level5: [],
      }));
    }

    if (name === "level4" && value?.length > 0) {
      var temp4 = [];
      temp4.push(value);
      getAllLevel5(
        formData.customerCode,
        formData.level1,
        formData.level2,
        formData.level3,
        temp4
      );
      setFormData((prevFormData) => ({
        ...prevFormData,

        level5: [],
      }));
    }
  };

  const checkExistingTableData = (reqBody) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/row/checkRowLevel`,
        reqBody
      )
      .then((res) => {
        if (res?.data?.message === "empty") {
          setExistingTable([]);
        } else {
          const newResVal = nullHandlerHelper(res?.data?.data);
          setExistingTable(newResVal);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getAllLevel1 = async (val) => {
    const reqBody = {
      customerCode: val,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL1`,
        reqBody
      );

      var newLevel1Arr = jsonTransformLevel1(response?.data?.data);
      var paramsLevel1 = jsonAllLevel1(response?.data?.data);
      setAllL1Params(paramsLevel1);
      await getAllLevel2(val, paramsLevel1); // Assuming getAllLevel1 is an asynchronous function.
      const sortedData = newLevel1Arr
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
      setLevel1Arr(sortedData);
    } catch (err) {
      // console.log(err);
    }
  };

  const getAllLevel2 = async (cc, val) => {
    const reqBody = {
      customerCode: cc,
      memberL1: val[0] === "All" ? allL1Params : val,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL2`,
        reqBody
      );

      var newLevel2Arr = jsonTransformLevel2(response?.data?.data);
      var paramsLevel2 = jsonAllLevel2(response?.data?.data);

      setAllL2Params(paramsLevel2);
      await getAllLevel3(cc, val, paramsLevel2);

      const sortedData = newLevel2Arr
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
      setLevel2Arr(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllLevel3 = async (cc, m1, val) => {
    const reqBody = {
      customerCode: cc,
      memberL1: m1[0] === "All" ? allL1Params : m1,
      memberL2: val[0] === "All" ? allL2Params : val,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL3`,
        reqBody
      );

      var newLevel3Arr = jsonTransformLevel3(response?.data?.data);
      var paramsLevel3 = jsonAllLevel3(response?.data?.data);
      setAllL3Params(paramsLevel3);
      await getAllLevel4(cc, m1, val, paramsLevel3);

      const sortedData = newLevel3Arr
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
      setLevel3Arr(sortedData);
      //   await getAllLevel1(paramsLevel2);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllLevel4 = async (cc, m1, m2, val) => {
    const reqBody = {
      customerCode: cc,
      memberL1: m1[0] === "All" ? allL1Params : m1,
      memberL2: m2[0] === "All" ? allL2Params : m2,
      memberL3: val[0] === "All" ? allL3Params : val,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL4`,
        reqBody
      );

      var newLevel4Arr = jsonTransformLevel4(response?.data?.data);
      var paramsLevel4 = jsonAllLevel4(response?.data?.data);
      setAllL4Params(paramsLevel4);
      await getAllLevel5(cc, m1, m2, val, paramsLevel4);

      setLevel4Arr(newLevel4Arr);
      //   await getAllLevel1(paramsLevel2);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllLevel5 = async (cc, m1, m2, m3, val) => {
    const reqBody = {
      customerCode: cc,
      memberL1: m1[0] === "All" ? allL1Params : m1,
      memberL2: m2[0] === "All" ? allL2Params : m2,
      memberL3: m3[0] === "All" ? allL3Params : m3,
      memberL4: val[0] === "All" ? allL4Params : val,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/security/row/list/memberL5`,
        reqBody
      );

      var newLevel5Arr = jsonTransformLevel5(response?.data?.data);
      var paramsLevel5 = jsonAllLevel5(response?.data?.data);
      setAllL5Params(paramsLevel5);

      setLevel5Arr(newLevel5Arr);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdd = () => {
    if (formData.groupName === "" && formData.userName === "") {
      toast("Please select either Group name or Username", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (formData.customerCode === "") {
      toast("Please select a customer code", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      setAddLoader(true);
      let newSavedRequests = [...savedRequests];

      if (noRestrictions === "selected") {
        var reqBody = {
          slNumber: nextSLNumber,
          userName: formData.userName === "" ? null : formData.userName,
          groupName: formData.groupName === "" ? null : formData.groupName,
          customerCode: formData.customerCode,
          level1All: "All",
          level2All: "All",
          level3All: "All",
          level4All: "All",
          level5All: "All",
        };

        newSavedRequests.push(reqBody);
      } else {
        var l1 = "";
        var l2 = "";
        var l3 = "";
        var l4 = "";
        var l5 = "";
        if (formData.level1.length === 0 || formData.level1[0] === "") {
          l1 = "All";
        } else {
          l1 = formData.level1[0];
        }
        if (formData.level2.length === 0 || formData.level2[0] === "") {
          l2 = "All";
        } else {
          l2 = formData.level2[0];
        }

        if (formData.level3.length === 0 || formData.level3[0] === "") {
          l3 = "All";
        } else {
          l3 = formData.level3[0];
        }

        if (formData.level4.length === 0 || formData.level4[0] === "") {
          l4 = "All";
        } else {
          l4 = formData.level4[0];
        }
        if (formData.level5.length === 0 || formData.level5[0] === "") {
          l5 = "All";
        } else {
          l5 = formData.level5[0];
        }

        var reqBody = {
          slNumber: nextSLNumber,
          userName: formData.userName === "" ? null : formData.userName,
          groupName: formData.groupName === "" ? null : formData.groupName,
          customerCode: formData.customerCode,
          level1All: l1,
          level2All: l2,
          level3All: l3,
          level4All: l4,
          level5All: l5,
        };
        newSavedRequests.push(reqBody);
      }
      /**
       * !make api call for validation from backend
       */
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/security/row/rowLevelValidation`,
          reqBody
        )
        .then((res) => {
          if (res.data.message === "empty") {
            const isPresent = savedRequests.some((obj) =>
              areObjectsEqual(obj, reqBody)
            );
            if (isPresent) {
              toast("Added combination already added", {
                type: "error",
                theme: "dark",
                autoClose: 2000,
              });
            } else {
              setSavedRequests(newSavedRequests);
              setNextSLNumber(nextSLNumber + 1); // Increment the SL number for the next item
            }
          } else {
            toast("Added combination already exists", {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
          }
          setAddLoader(false);
        })
        .catch((err) => {
          // console.log(err);
          setAddLoader(false);
        });
    }
  };

  function areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1).filter((key) => key !== "slNumber");
    const keys2 = Object.keys(obj2).filter((key) => key !== "slNumber");

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  }

  const handleSubmit = () => {
    if (savedRequests.length === 0) {
      toast("Please add a row", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      setSubmitLoader(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/security/row/create`,
          savedRequests
        )
        .then((res) => {
          refreshTable();
          toast("Added row level security successfully", {
            type: "success",
            theme: "dark",
            autoClose: 2000,
          });
          setSavedRequests([]);
          setFormData((prevFormData) => ({
            ...prevFormData,
            groupName: "",
            userName: "",
            customerCode: "",
            level1: [],
            level2: [],
            level3: [],
            level4: [],
            level5: [],
          }));
          setNoRestrictions("");
          setExistingTable([]);
          setSubmitLoader(false);
          const reqBody = {
            userName: formData.userName === "" ? "null" : formData.userName,
            groupName: formData.groupName === "" ? "null" : formData.groupName,
            customerCode: formData.customerCode,
          };
          // checkExistingTableData(reqBody);
        })
        .catch((err) => {
          // console.log(err);
          setSubmitLoader(false);
          toast("Some error occured", {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        });
    }
  };
  const resetAll = () => {
    setSavedRequests([]);
    setExistingTable([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      groupName: "",
      userName: "",
      customerCode: "",
      level1: [],
      level2: [],
      level3: [],
      level4: [],
      level5: [],
    }));
    setNoRestrictions("");
  };

  const deleteAll = () => {
    setSavedRequests([]);
    setExistingTable([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      groupName: "",
      userName: "",
      customerCode: "",
      level1: [],
      level2: [],
      level3: [],
      level4: [],
      level5: [],
    }));
    setNoRestrictions("");
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    if (isChecked === true) {
      setNoRestrictions("");
    } else {
      setNoRestrictions("selected");
      setFormData((prevFormData) => ({
        ...prevFormData,
        level1: ["All"],
        level2: ["All"],
        level3: ["All"],
        level4: ["All"],
        level5: ["All"],
      }));
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    //     // setUserList(res.data.UserList);
    const newRes = jsonTransform(userListG);
    const sortedData = newRes
      .slice()
      .sort((a, b) => a.label.localeCompare(b.label));
    setUserList(sortedData);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quickSight/groupList`, {
        headers: headers,
      })
      .then((response) => {
        const newGroupRes = jsonTransformGroup(response.data.data.GroupList);
        const sortedData = newGroupRes
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        setGroupList(sortedData);
      })
      .catch((error) => {
        console.error("Error:", error.response);
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/security/row/list/customerCode`)
      .then((res) => {
        const newCustomerCode = jsonTransformCustomerCode(res?.data?.data);
        const sortedData = newCustomerCode
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        setCustomerCode(sortedData);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ArrowBackOutlinedIcon
          onClick={updateParentState}
          style={{ cursor: "pointer" }}
        />
        <Typography variant="h6" sx={{ p: 1 }}>
          Add row level security
        </Typography>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth>
            <Autocomplete
              id="groupName"
              name="groupName"
              options={groupList}
              getOptionLabel={(option) => option.label}
              value={
                groupList.find(
                  (option) => option.value === formData.groupName
                ) || null
              }
              onChange={(event, newValue) =>
                handleInputChange(event, newValue, "groupName")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Group Name"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              )}
              disabled={formData.userName !== "" ? true : false}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth>
            <Autocomplete
              id="userName"
              options={userList}
              getOptionLabel={(option) => option.label}
              value={
                userList.find((option) => option.value === formData.userName) ||
                null
              }
              name="userName"
              onChange={(event, newValue) =>
                handleInputChange(event, newValue, "userName")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Username"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              )}
              disabled={formData.groupName !== "" ? true : false}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <FormControl fullWidth>
            <Autocomplete
              id="customerCode"
              options={customerCode}
              getOptionLabel={(option) => option.label}
              value={
                customerCode.find(
                  (option) => option.value === formData.customerCode
                ) || null
              }
              name="customerCode"
              onChange={(event, newValue) =>
                handleInputChange(event, newValue, "customerCode")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Customer Code"
                  variant="outlined"
                  size="small"
                  margin="dense"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <FormControl fullWidth size="small" margin="dense" variant="outlined">
            <InputLabel id="level1-label">Level 1</InputLabel>
            <Select
              labelId="level1-label"
              id="level1"
              value={formData.level1}
              label="level1-Select"
              name="level1"
              disabled={
                formData.customerCode === "" || noRestrictions === "selected"
                  ? true
                  : false
              }
              onChange={handleInputChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 250, overflowY: "auto" },
                },
              }}
            >
              <MenuItem key="select" value="">
                <ListItemText primary="Select a value" />
              </MenuItem>
              <MenuItem key="All" value="All">
                <ListItemText primary="All" />
              </MenuItem>
              {level1Arr.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <FormControl fullWidth size="small" margin="dense" variant="outlined">
            <InputLabel id="level2-label">Level 2</InputLabel>
            <Select
              labelId="level2-label"
              id="level2"
              value={formData.level2}
              label="level2-Select"
              name="level2"
              disabled={
                formData.customerCode === "" || noRestrictions === "selected"
                  ? true
                  : false
              }
              onChange={handleInputChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 250, overflowY: "auto" },
                },
              }}
            >
              <MenuItem key="select" value="">
                <ListItemText primary="Select a value" />
              </MenuItem>
              <MenuItem key="All" value="All">
                <ListItemText primary="All" />
              </MenuItem>
              {level2Arr.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <FormControl fullWidth size="small" margin="dense" variant="outlined">
            <InputLabel id="level3-label">Level 3</InputLabel>
            <Select
              labelId="level3-label"
              id="level3"
              value={formData.level3}
              label="level3-Select"
              name="level3"
              disabled={
                formData.customerCode === "" || noRestrictions === "selected"
                  ? true
                  : false
              }
              onChange={handleInputChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 250, overflowY: "auto" },
                },
              }}
            >
              <MenuItem key="select" value="">
                <ListItemText primary="Select a value" />
              </MenuItem>
              <MenuItem key="All" value="All">
                <ListItemText primary="All" />
              </MenuItem>
              {level3Arr.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <FormControl fullWidth size="small" margin="dense" variant="outlined">
            <InputLabel id="level4-label">Level 4</InputLabel>
            <Select
              labelId="level4-label"
              id="level4"
              value={formData.level4}
              label="level4-Select"
              name="level4"
              disabled={
                formData.customerCode === "" || noRestrictions === "selected"
                  ? true
                  : false
              }
              onChange={handleInputChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 250, overflowY: "auto" },
                },
              }}
            >
              <MenuItem key="select" value="">
                <ListItemText primary="Select a value" />
              </MenuItem>
              <MenuItem key="All" value="All">
                <ListItemText primary="All" />
              </MenuItem>
              {level4Arr.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <FormControl fullWidth size="small" margin="dense" variant="outlined">
            <InputLabel id="level5-label">Level 5</InputLabel>
            <Select
              labelId="level5-label"
              id="level5"
              value={formData.level5}
              label="level5-Select"
              name="level5"
              disabled={
                formData.customerCode === "" || noRestrictions === "selected"
                  ? true
                  : false
              }
              onChange={handleInputChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={{
                PaperProps: {
                  sx: { maxHeight: 250, overflowY: "auto" },
                },
              }}
            >
              <MenuItem key="select" value="">
                <ListItemText primary="Select a value" />
              </MenuItem>
              <MenuItem key="All" value="All">
                <ListItemText primary="All" />
              </MenuItem>

              {level5Arr.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                disabled={formData.customerCode === "" ? true : false}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={isChecked ? "No restrictions" : "No restrictions "}
          />
        </Grid>
      </Grid>
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          gap: "10px",
          paddingTop: "2%",
        }}
      >
        {addLoader ? (
          <div className="loader"></div>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={handleAdd}
              sx={{ backgroundColor: primaryColor1 }}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              onClick={resetAll}
              sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={deleteAll}
              sx={{ backgroundColor: primaryColor1 }}
            >
              Delete All
            </Button>
          </>
        )}
      </div>
      <div style={{ paddingTop: "2%" }}>
        <Typography>{"Row Level Security to be saved:"}</Typography>
      </div>
      <DataGrid
        rows={savedRequests}
        columns={columns}
        getRowId={(row) => row.slNumber}
        initialState={{
          ...savedRequests.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
          columns: {
            columnVisibilityModel: {
              slNumber: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
      />
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          gap: "10px",
          paddingTop: "2%",
        }}
      >
        {addLoader ? (
          <div className="loader"></div>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ backgroundColor: primaryColor1 }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: primaryColor1 }}
              onClick={updateParentState}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
      <Dialog
        open={deleteOpen}
        onClose={handleClickDeleteClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            {"Delete selected row  "}
            <span></span>
          </DialogTitle>
          <DialogTitle>
            <CloseIcon
              onClick={handleClickDeleteClose}
              className="pointerCursor"
            />
          </DialogTitle>
        </div>

        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete`}
            {"  "}
            <span></span> {"?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: primaryColor1 }}
            onClick={handleTempDeleteUser}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: primaryColor1 }}
            onClick={handleClickDeleteClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={existingDeleteOpen}
        onClose={handleClickExistingDeleteClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            {"Delete selected row  "}
            <span></span>
          </DialogTitle>
          <DialogTitle>
            <CloseIcon
              onClick={handleClickExistingDeleteClose}
              className="pointerCursor"
            />
          </DialogTitle>
        </div>

        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete`}
            {"  "}
            <span></span> {"?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Button
            variant="contained"
            sx={{ backgroundColor: primaryColor1 }}
            onClick={handleExistingDeleteUser}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: primaryColor1 }}
            onClick={handleClickExistingDeleteClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ paddingTop: "2%" }}>
        <Typography>{"Existing Row Level Security:"}</Typography>
      </div>

      <DataGrid
        rows={existingTable}
        columns={columnsExistingTable}
        getRowId={(row) => row.id}
        initialState={{
          ...existingTable.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 25]}
        disableRowSelectionOnClick
      />
      <ToastContainer />
    </div>
  );
}

export default AddRowLevelSecurityV2;
