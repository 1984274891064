import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { Radio } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { primaryColor1 } from "../../../commonStyles/styles";

const RequiredAsterisk = styled("span")({
  color: "red", // Customize the color or any other styles here
});

const EditModal2 = ({ show, handleClose, initialData, handleSave }) => {
  const [MenuName, setTitle] = useState("");
  const [isMenuParent, setHasChild] = useState(false);
  const [DashboardId, setDashboardId] = useState("");
  const [SheetId, setSheetId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [valueRadio, setValueRadio] = useState(1);

  useEffect(() => {
    if (initialData) {
      setTitle(initialData.MenuName || "");
      setHasChild(initialData.isMenuParent || false);
      setDashboardId(initialData.DashboardId || "");
      setSheetId(initialData.SheetId || "");
      setValueRadio(
        initialData.MenuType === "analytics"
          ? 1
          : initialData.MenuType === "q"
          ? 2
          : 0
      );
      setTopicId(initialData.TopicId);
    }
  }, [initialData]);

  const onRadioChange = (e) => {
    setValueRadio(e.target.value);
  };

  useEffect(() => {
    if (isMenuParent) {
      setDashboardId("");
      setSheetId("");
    }
  }, [isMenuParent]);

  const handleInputChange = (event, stateSetter) => {
    stateSetter(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();

    if (MenuName === "") {
      toast("Menu Name cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (
      valueRadio === 1 &&
      DashboardId === "" &&
      isMenuParent === false
    ) {
      toast("Dashboard ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (valueRadio === 1 && SheetId === "" && isMenuParent === false) {
      toast("Sheet ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (valueRadio === 2 && topicId === null && isMenuParent === false) {
      toast("Topic ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (valueRadio === 2 && topicId === "" && isMenuParent === false) {
      toast("Topic ID cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      handleSave({
        MenuName,
        isMenuParent,
        DashboardId,
        SheetId,
        topicId,
        valueRadio,
      });
      handleClose();
    }
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Edit Child Node</DialogTitle>
        <DialogTitle>
          <CloseIcon onClick={handleClose} className="pointerCursor" />
        </DialogTitle>
      </div>
      <DialogContent>
        <form onSubmit={handleEditSubmit}>
          <div style={{ padding: "1%" }}>
            <Radio.Group onChange={onRadioChange} value={valueRadio}>
              <Radio value={1}>Analytics</Radio>
              <Radio value={2}>Q</Radio>
            </Radio.Group>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label={
              <span>
                Menu
                <RequiredAsterisk>*</RequiredAsterisk>
              </span>
            }
            fullWidth
            variant="outlined"
            type="text"
            value={MenuName}
            // required
            onChange={(e) => handleInputChange(e, setTitle)}
          />

          {!isMenuParent && valueRadio === 1 && (
            <>
              <TextField
                margin="dense"
                id="dashboardId"
                label={
                  <span>
                    Dashboard ID
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={DashboardId}
                onChange={(e) => handleInputChange(e, setDashboardId)}
              />

              <TextField
                margin="dense"
                id="sheetId"
                label={
                  <span>
                    Sheet ID
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={SheetId}
                onChange={(e) => handleInputChange(e, setSheetId)}
              />
            </>
          )}
          {!isMenuParent && valueRadio === 2 && (
            <>
              <TextField
                margin="dense"
                id="topicId"
                label={
                  <span>
                    Topic Id
                    <RequiredAsterisk>*</RequiredAsterisk>
                  </span>
                }
                fullWidth
                variant="outlined"
                type="text"
                value={topicId}
                onChange={(e) => setTopicId(e.target.value)}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Switch
                checked={isMenuParent}
                onChange={() => setHasChild(!isMenuParent)}
              />
            }
            label="Has a Child"
          />
        </form>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={handleEditSubmit}
          sx={{ backgroundColor: primaryColor1 }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: primaryColor1 }}
          onClick={handleClose}
        >
          Cancel
        </Button>
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
};

export default EditModal2;
