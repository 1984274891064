import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CloudQLogo from "../../assets/CQWIk-1.png";
import LoginLeft from "../../assets/loginLeft.jpg";
import {
  Grid,
  TextField,
  Checkbox,
  Button,
  Box,
  Typography,
} from "@mui/material";
import "./login.scss"; // Import your custom CSS file
import { buttonColorCommon } from "../commonStyles/styles";
import {
  loginUser,
  loginUserName,
  loginUserData,
  setUserToken,
} from "./LoginSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./loader.scss";

import axios from "axios";

function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (event) => {
    setUserName(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const Loader = () => {
    return (
      <div className="spinner-outq">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  const validateToken = async (token) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.AccessToken}`,
        OrganizationId: "95145b12-447b-4716-b6c8-040361d266f0",
        // Add more headers if needed
        // "AnotherHeader": "Value"
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/tokenValidate`,
        {},
        { headers: headers }
      );

      // Assuming your API response has a status field to indicate success
      if (response?.data?.status === true) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // console.log(error);
      return false;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    setIsLoader(true);
    const reqBody = {
      organizationId: "95145b12-447b-4716-b6c8-040361d266f0",
      username: userName,
      password: password,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/auth/signin`,
        reqBody
      );

      // if(res?.data?.message ==="User not found")
      const isValidToken = await validateToken(res?.data?.data?.AuthKeys);

      if (isValidToken) {
        dispatch(loginUserName(res.data?.data.UserName));
        dispatch(loginUserData(res.data?.data));
        dispatch(setUserToken(res?.data?.data?.AuthKeys));
        setIsLoader(false);

        if (res.data.data.CqwikRole === 1) {
          dispatch(loginUser("admin"));
          navigate("/home");
        } else {
          dispatch(loginUser("user"));
          navigate("/home");
        }
      } else {
        // Handle the case when the token validation fails

        if (res?.data?.message === "User not found") {
          toast("User not found", {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        } else {
          toast("Token Validation failed", {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        }
        // Show an error message to the user, or take appropriate action
      }
    } catch (error) {
      // console.log(error);
      toast("Please Check Credentials", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="login-container">
      <Grid container>
        {/* Left side image */}
        <Grid item xs={12} md={6} className="image-container">
          <div
            className="image-wrapper"
            style={{ backgroundImage: `url(${LoginLeft})` }}
          ></div>
        </Grid>

        {/* Right side login form */}
        <Grid item xs={12} md={6} className="form-container">
          {/* <Paper elevation={3}> */}
          <Box p={4}>
            <Box className="content-center" mb={4}>
              <img className="cloudqLogo" src={CloudQLogo} alt="CloudQLogo" />
            </Box>

            <Typography
              className="content-center"
              style={{ fontWeight: "bold", paddingBottom: "2%" }}
              variant="h6"
            >
              Login
            </Typography>
            <Typography
              className="content-center"
              style={{ textAlign: "center", fontWeight: 300 }}
            >
              Welcome back,
            </Typography>
            <Typography
              className="content-center"
              style={{
                textAlign: "center",
                fontWeight: 300,
                paddingBottom: "10%",
              }}
            >
              Login with your data that you entered during registration.
            </Typography>
            <form>
              <TextField
                id="username"
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                name="username"
                onKeyPress={handleKeyPress}
                onChange={onChange}
              />
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                name="password"
                type="password"
                onKeyPress={handleKeyPress}
                onChange={onPasswordChange}
              />
              <Grid container alignItems="center">
                <Grid item>
                  <Checkbox color="primary" />
                </Grid>
                <Grid item>
                  <label>Remember my preference</label>
                </Grid>
              </Grid>
              <Button
                variant="contained"
                fullWidth
                onClick={handleLogin}
                sx={{ backgroundColor: buttonColorCommon }}
              >
                Log In
              </Button>
              <ToastContainer />
            </form>
          </Box>
          {/* </Paper> */}
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;
