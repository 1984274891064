import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Grid,
  Avatar,
  Box,
  Container,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Button,
  Stack,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavbarLogo from "../../assets/navbar-logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import "./navbar.scss";
import { buttonColorCommon } from "../commonStyles/styles";
import { loginUser, currentUserName, loginUserData } from "../user/LoginSlice";
import { userListGlobal } from "../admin/AdminSlice";

const pages = ["Home", "Dashboard"];
const INACTIVITY_TIMEOUT = 10 * 60 * 1000;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  if (!name) {
    return {}; // Return an empty object if name is undefined or null
  }

  const initials = name
    .split(" ")
    .map((word) => word[0].toUpperCase())
    .join("");

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: initials,
  };
}

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(currentUserName);

  const handleLogout = () => {
    dispatch(loginUser(""));
    dispatch(userListGlobal([]));
    dispatch(loginUserData(""));
    navigate("/login");
  };

  // A function to start the inactivity timer
  const startInactivityTimer = () => {
    return setTimeout(() => {
      // Perform logout after the inactivity timeout
      handleLogout();
    }, INACTIVITY_TIMEOUT);
  };

  // Initialize the inactivity timer when the component mounts
  useEffect(() => {
    let timerId;

    // Start the inactivity timer
    const startTimer = () => {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = startInactivityTimer();
    };

    // Add event listeners to reset the timer when the user interacts with the app
    window.addEventListener("mousemove", startTimer);
    window.addEventListener("keydown", startTimer);

    // Start the initial inactivity timer
    startTimer();

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("mousemove", startTimer);
      window.removeEventListener("keydown", startTimer);
      clearTimeout(timerId);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    /* eslint-disable-next-line */

    if (page === "Home") {
      navigate("/home");
    } else if (page === "Dashboard") {
      navigate("/analytics");
    }
  };

  return (
    <div>
      <AppBar position="sticky" sx={{ backgroundColor: buttonColorCommon }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/home"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img className="logo" src={NavbarLogo} alt="Logo" />
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <img className="logo" src={NavbarLogo} alt="Logo" />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    marginRight: "10px", // Adjust spacing between buttons if needed
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    textOverflow: "ellipsis", // Show ellipsis if text exceeds available space
                    overflow: "hidden", // Hide any overflowing text
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <div style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  <label>{currentUser}</label>
                </div>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2}>
                  <Avatar {...stringAvatar(currentUser)} />
                </Stack>
              </Grid>
              <Grid item>
                <div className="logout-align">
                  <LogoutIcon onClick={handleLogout} className="logout-icon" />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Navbar;
