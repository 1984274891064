import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import "./admin.scss";
import "./loader.scss";
import { primaryColor1, whiteColor, redColor } from "../commonStyles/styles";
import {
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { routeName } from "../commonStrings/CommonStrings";
import {
  currentUserData,
  selectBiLogin,
  currentUserName,
} from "../user/LoginSlice";
import DeleteIcon from "../../assets/deleteTable.png";
import EditTableIcon from "../../assets/editTable.png";
import axios from "axios";
import { postApi } from "../api/Api";
import "./manageUser.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditManageUser from "./EditManageUser";
import CloseIcon from "@mui/icons-material/Close";
import { currentUserList, userListGlobal } from "./AdminSlice";

function RenderCell({ value, colDef }) {
  const [selectedRole, setSelectedRole] = useState(value);
  const [roleList, setRoleList] = useState([]);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <Select
      value={selectedRole}
      onChange={handleRoleChange}
      style={{ width: "100%", height: "80%" }}
    >
      {colDef.valueOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function ManageUser() {
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [cognitoUserName, setCognitoUserName] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    name: "",
    // emailId: "",
    password: "",
    userStatus: "",
    s3Path: "",
    emailId: "",
    s3Status: false,
    role: "",
    cqwik: ""
  });
  const [buttonLoader, setButtonLoader] = useState(false);

  const userListG = useSelector(currentUserList);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const [menuList, setMenuList] = useState([]);
  const currentUser = useSelector(currentUserName);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [editList, setEditList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderInitial, setLoaderInitial] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);

  const dispatch = useDispatch();

  const CqwikRoleList = [
    { label: "Admin", value: 1 },
    { label: "User", value: 2 },
  ];

  const columns = [
    {
      field: "CognitoUserName",
      headerName: "Username",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Username"}</strong>,
    },
    {
      field: "Name",
      headerName: "Name",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Name"}</strong>,
    },
    {
      field: "S3Path",
      headerName: "S3Path",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"S3Path"}</strong>,
    },
    {
      field: "S3Status",
      headerName: "S3Status",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"S3Status"}</strong>,
    },
    {
      field: "Email",
      headerName: "Email",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Email"}</strong>,
    },
    // {
    //   field: "CognitoUserRole",
    //   headerName: "Role",
    //   headerClassName: "super-app-theme--header",

    //   flex: 1,
    //   renderHeader: () => <strong>{"Role"}</strong>,

    //   renderCell: (params) => {
    //     const role = params.value;

    //     if (role === 1) {
    //       return "Admin";
    //     } else if (role === 2) {
    //       return "Author";
    //     } else if (role === 3) {
    //       return "Reader";
    //     } else {
    //       return role;
    //     }
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",

      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong>{"Actions"}</strong>,
      disableColumnMenu: true,
      sortable: false,
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const { id } = params;
        const handleDelete = () => {
          // Perform delete action based on the row data

          setDeleteId(params.row.Email);
          setCognitoUserName(params.row.CognitoUserName);
          handleClickDeleteOpen();
        };

        const handleEdit = () => {
          handleClickEditOpen();
          setEditList(params.row);
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align icons vertically in the center
              gap: "10px", // Add spacing between the icons
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className="bg-color pointerCursor"
              onClick={handleDelete}
            />
            <img
              src={EditTableIcon}
              alt="Edit"
              className="bg-color pointerCursor"
              onClick={handleEdit}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getTableDetails();
  }, []);

  const refreshTable = () => {
    dispatch(userListGlobal([]));
    getTableDetails();
  };

  const getTableDetails = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization?.OrganizationId
        : userData?.Organization?.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken?.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quickSight/userList`, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here

        setMenuList(response.data.data.UserList);
        dispatch(userListGlobal(response.data.data.UserList));
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };

  const handleDeleteUser = () => {
    setLoaderDelete(true);
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization?.OrganizationId
        : userData?.Organization?.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken?.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const reqBody = {
      userName: deleteId,
      organizationId: organizationId,
    };

    const deleteUserCognitoReqBody = {
      organizationId: organizationId,
      username: cognitoUserName,
    };

    postApi(
      "/auth/delete",
      deleteUserCognitoReqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        getTableDetails();
        handleClickDeleteClose();
        toast("User deleted successfully", {
          type: "success",
          theme: "dark",
          autoClose: 2000,
        });
        setLoaderDelete(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoaderDelete(false);
        handleClickDeleteClose();
      });
  };

  const handleClickEditOpen = () => {
    setOpenEdit(true);
  };

  const handleClickEditClose = () => {
    setOpenEdit(false);
  };

  const handleClickDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggleS3 = (e) => {
    setToggleStatus(!toggleStatus);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoader(true);
    if (formData.userName.trim() === "") {
      toast("Username cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (formData.name.trim() === "") {
      toast("Name cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (formData.emailId.trim() === "") {
      toast("Email cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (!isValidEmail(formData.emailId)) {
      toast("Invalid email format", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (formData.role === "") {
      toast("Role cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (formData.cqwik === "") {
      toast("Cqwik Role cannot be empty", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    }else {
      setLoader(true);
      const accessToken =
        pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;
      const organizationId =
        pathnames[0] === routeName
          ? biUserData?.Organization.OrganizationId
          : userData?.Organization.OrganizationId;

      const reqBody = {
        organizationId: organizationId,
        username: formData?.userName?.toLowerCase(),
        password: formData.password === "" ? "12345678" : formData.password,
        email: formData.emailId,
        name: formData.name,
        s3status: formData.s3Status,
        s3path: formData.s3Path,
        role: formData.cqwik
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth/signup`, reqBody)
        .then((res) => {
          const reqBodyUserSub = {
            username: formData?.userName?.toLowerCase(),
          };
          const organizationId =
            pathnames[0] === routeName
              ? biUserData?.Organization.OrganizationId
              : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

          const headers = {
            "Content-Type": "application/json",
            organizationId: organizationId,
          };
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/auth/adminConfirm`,
              reqBodyUserSub,
              { headers }
            )
            .then((res) => {
              const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken.AccessToken}`,
                organizationId: organizationId,
              };
              postApi(
                "/quickSight/createUser",
                {
                  email: formData.emailId,
                  username: formData?.userName?.toLowerCase(),
                  role: formData.role,
                },
                accessToken.AccessToken,
                organizationId,
                headers
              )
                .then((res) => {
                  toast(res.message, {
                    type: "success",
                    theme: "dark",
                    autoClose: 2000,
                  });
                  getTableDetails();
                  setFormData({
                    userName: "",
                    name: "",
                    emailId: "",
                    password: "",
                    userStatus: "",
                    s3Path: "",
                    s3Status: false,
                    role: "",
                    cqwik: "",
                  });
                  setLoader(false);
                  setButtonLoader(false);
                  handleClose();
                })
                .catch((err) => {
                  // console.log(err);
                  setLoader(false);
                  setButtonLoader(false);
                  setFormData({
                    userName: "",
                    name: "",
                    emailId: "",
                    password: "",
                    userStatus: "",
                    s3Path: "",
                    s3Status: false,
                    role: "",
                    cqwik: "",
                  });
                });
            })
            .catch((err) => {
              // console.log(err);
              setLoader(false);
              setButtonLoader(false);
              setFormData({
                userName: "",
                name: "",
                emailId: "",
                password: "",
                userStatus: "",
                s3Path: "",
                s3Status: false,
                role: "",
              });
              toast(err.response.data.message, {
                type: "error",
                theme: "dark",
                autoClose: 2000,
              });
            });
        })
        .catch((err) => {
          setLoader(false);
          setButtonLoader(false);
          toast(err.response.data.message, {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        });
    }
    setButtonLoader(false);
  };

  const handleReset = () => {
    setFormData({
      userName: "",
      name: "",
      emailId: "",
      password: "",
      userStatus: "",
      s3Path: "",
      s3Status: false,
      role: "",
      cqwik: "",
    });
    setShowPassword(false);
  };
  const filterMenuList = () => {
    return userListG.filter(
      (item) =>
        item?.CognitoUserName.toLowerCase().includes(
          searchQuery.toLowerCase()
        ) ||
        item?.Name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Email?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const LoaderInitial = () => {
    return (
      <div className="spinner-outq">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/quickSight/getRoles`);
        const result = await response.json();
        if (result.status) {
          const transformedRoles = result.data.RoleList.map(role => ({
            label: role.RoleName.charAt(0).toUpperCase() + role.RoleName.slice(1),
            value: role.RoleId,
          }));
          setRoleList(transformedRoles);
        }
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchRoles();
  }, []);


  return (
    <Paper elevation={1} sx={{ borderRadius: "5px" }}>
      <Box
        sx={{
          p: 4,
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: primaryColor1,
            color: whiteColor,
          },
        }}
      >
        <Typography variant="h6" className="sub-header">
          Manage User
        </Typography>
        <div
          className="top-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            size="medium"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleClickOpen}
            sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
          >
            Add User
          </Button>

          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <TextField
              id="outlined-basic"
              label="Search User"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <EditManageUser
            editOpen={openEdit}
            editClose={handleClickEditClose}
            editList={editList}
            tableRefresh={getTableDetails}
          />
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>Add New User</DialogTitle>
              <DialogTitle>
                <CloseIcon onClick={handleClose} className="pointerCursor" />
              </DialogTitle>
            </div>

            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoFocus
                      size="small"
                      margin="dense"
                      id="username"
                      label={
                        <span>
                          Username
                          <Typography component="span" sx={{ color: redColor }}>
                            *
                          </Typography>
                        </span>
                      }
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="userName"
                      value={formData.userName}
                      onChange={handleInputChange}
                      // required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      margin="dense"
                      id="name"
                      label={
                        <span>
                          Name
                          <Typography component="span" sx={{ color: redColor }}>
                            *
                          </Typography>
                        </span>
                      }
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      margin="dense"
                      id="password"
                      label={<span>Password</span>}
                      fullWidth
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      margin="dense"
                      id="emailId"
                      label={
                        <span>
                          Email
                          <Typography component="span" sx={{ color: redColor }}>
                            *
                          </Typography>
                        </span>
                      }
                      fullWidth
                      variant="outlined"
                      type="email"
                      name="emailId"
                      value={formData.emailId}
                      onChange={handleInputChange}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    >
                      <InputLabel id="role-label">
                        <span>
                        QuickSight Role 
                          <Typography component="span" sx={{ color: redColor }}>
                            *
                          </Typography>
                        </span>
                      </InputLabel>
                      <Select
                        labelId="role"
                        id="role"
                        value={formData.role}
                        label="QuickSight Role"
                        name="role"
                        onChange={handleInputChange}
                      >
                        {roleList.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      margin="dense"
                      fullWidth
                    >
                      <InputLabel id="cqwik-label">
                        <span>
                          Cqwik Role 
                          <Typography component="span" sx={{ color: redColor }}>
                            *
                          </Typography>
                        </span>
                      </InputLabel>
                      <Select
                        labelId="cqwik"
                        id="cqwik"
                        value={formData.cqwik}
                        label="Cqwik Role"
                        name="cqwik"
                        onChange={handleInputChange}
                      >
                        {CqwikRoleList.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <TextField
                        disabled={toggleStatus ? false : true}
                        size="small"
                        margin="dense"
                        id="s3Path"
                        label="S3 Path"
                        fullWidth
                        variant="outlined"
                        type="text"
                        name="s3Path"
                        value={formData.s3Path}
                        onChange={handleInputChange}
                      />
                      <div className="toggle-switch">
                        <input
                          className="toggle-input"
                          id="toggle"
                          type="checkbox"
                          onClick={handleToggleS3}
                        />
                        <label className="toggle-label" htmlFor="toggle" />
                      </div>
                    </div>
                  </Grid>


                </Grid>
              </form>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
              }}
            >
              {loader ? (
                <div className="loader"></div>
              ) : (
                <>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{ backgroundColor: primaryColor1 }}
                  >
                    Save
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={handleReset}
                    sx={{
                      backgroundColor: whiteColor,
                      color: primaryColor1,
                      borderColor: primaryColor1,
                    }}
                  >
                    RESET
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: primaryColor1 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </DialogActions>
            <ToastContainer />
          </Dialog>
        </div>
        {userListG?.length > 0 ? (
          <DataGrid
            rows={filterMenuList()}
            columns={columns}
            getRowId={(row) => row.CognitoUserName}
            initialState={{
              ...userListG.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
              columns: {
                columnVisibilityModel: {
                  S3Path: false,
                  S3Status: false,
                  Email: false,
                },
              },
            }}
            pageSizeOptions={[10, 25]}
            disableRowSelectionOnClick
          />
        ) : (
          <LoaderInitial />
        )}
      </Box>

      <Dialog
        open={openDelete}
        onClose={handleClickDeleteClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            {" "}
            {"Delete User  "}
            <span>{deleteId}</span>
          </DialogTitle>
          <DialogTitle>
            <CloseIcon
              onClick={handleClickDeleteClose}
              className="pointerCursor"
            />
          </DialogTitle>
        </div>

        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete`}
            {"  "}
            <span>{deleteId}</span> {"?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          {loaderDelete ? (
            <div className="loader"></div>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: whiteColor,
                  color: primaryColor1,
                  borderColor: primaryColor1,
                }}
                onClick={handleDeleteUser}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: primaryColor1 }}
                onClick={handleClickDeleteClose}
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Paper>
  );
}

export default ManageUser;
