import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import "./admin.scss";
import { primaryColor1, whiteColor, redColor } from "../commonStyles/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddGroupUser from "./AddGroupUser";
import { routeName } from "../commonStrings/CommonStrings";
import {
  currentUserData,
  selectBiLogin,
  loginUserData,
} from "../user/LoginSlice";
import { reloadPath } from "./AdminSlice";
import axios from "axios";
import DeleteIcon from "../../assets/deleteTable.png";
import AddUserIcon from "../../assets/addUser.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApi, deleteApi } from "../api/Api";
import CloseIcon from "@mui/icons-material/Close";

function ManageGroup() {
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [addGroup, setAddGroup] = useState(true);
  const [groupId, setGroupId] = useState("");
  const [groupDesc, setGroupDesc] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);

  const [formData, setFormData] = useState({
    groupName: "",
    description: "",
  });

  const [groupUserList, setGroupUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const dispatch = useDispatch();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const currentReload = useSelector(reloadPath);
  const menuList = [
    {
      group: "username-unique",
      description: "email@gmail.com",
    },
    {
      group: "username-uq",
      description: "email2@gmail.com",
    },
    {
      group: "test user",
      description: "test@gmail.com",
    },
  ];

  const columns = [
    {
      field: "GroupName",
      headerName: "Group Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Group Name"}</strong>,
      renderCell: (params) => {
        const { id } = params;

        const handleAddUser = () => {
          setAddGroup(false);
          setGroupId(id);
          setGroupDesc(params.row.description);
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              cursor: "pointer", // Add cursor pointer
            }}
            onClick={handleAddUser} // Attach onClick event handler
          >
            {params.value} {/* Display the content of the cell */}
          </div>
        );
      },
    },
    {
      field: "Description",
      headerName: "Description",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Description"}</strong>,
    },

    {
      field: "actions",
      headerName: "Actions",

      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong>{"Actions"}</strong>,

      flex: 1,
      renderCell: (params) => {
        const { id } = params;

        const handleDelete = () => {
          setDeleteId(id);
          getGroupUserList(id);
          setTimeout(() => {
            handleClickDeleteOpen();
          }, 750);
        };

        const handleAddUser = () => {
          setAddGroup(false);
          setGroupId(id);
          setGroupDesc(params.row.description);
        };

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center", // Align icons vertically in the center
              gap: "10px", // Add spacing between the icons
            }}
          >
            <img
              src={AddUserIcon}
              alt="add user"
              className="bg-color pointerCursor"
              onClick={handleAddUser}
            />
            <img
              src={DeleteIcon}
              alt="Delete"
              className="bg-color pointerCursor"
              onClick={handleDelete}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getTableDetails();
    setAddGroup(true);
  }, [currentReload]);

  const getGroupUserList = (id) => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const reqBody = {
      groupName: id,
    };
    postApi(
      "/quickSight/groupUserList",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        setGroupUserList(res.data.GroupMemberList);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const refreshAccessToken = async () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId
    const tokenUserName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const headers = {
      "Content-Type": "application/json",
      refreshToken: accessToken.RefreshToken,
      organizationId: organizationId,
      username: tokenUserName !== "" ? tokenUserName.toLowerCase() : "",
      // Add more headers if needed
    };

    // Make an API call to refresh the token using the refresh token
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/auth/tokenRefresh`,
        {},
        {
          headers,
        }
      )
      .then((res) => {
        if (pathnames[0] === routeName) {
        } else {
          const reqObject = {
            UserId: userData.UserId,
            AWSUserId: userData.AWSUserId,
            UserName: userData.UserName,
            StatusId: userData.StatusId,
            Status: userData.Status,
            RoleId: userData.RoleId,
            CqwikRole: userData.CqwikRole,
            Organization: userData.Organization,
            UserEmail: userData.UserEmail,
            AuthKeys: {
              AccessToken: res?.data?.data?.AuthenticationResult.AccessToken,
              RefreshToken: accessToken.RefreshToken,
            },
          };
          dispatch(loginUserData(reqObject));
        }
      })
      .catch((error) => {
        console.error("Token refresh failed:", error);
        return error;
      });
  };

  const getTableDetails = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quickSight/groupList`, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here

        setGroupList(response.data.data.GroupList);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
        var flag = true;
        if (
          error.response &&
          error.response.data.message === "Token expired" &&
          flag === true
        ) {
          // If the token has expired, refresh the token and retry the API call
          refreshAccessToken().then((res) => {
            flag = false;
          });
        }
      });
  };
  const handleClickDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterMenuList = () => {
    return groupList.filter(
      (item) =>
        item?.GroupName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Description?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };
    const reqBody = {
      groupName: formData.groupName,
      description: formData.description,
    };

    const groupNameToFind = formData?.groupName.toLowerCase();
    const isPresent = groupList.some(
      (group) => group?.GroupName.toLowerCase() === groupNameToFind
    );

    if (formData.groupName === "") {
      toast("Please fill Group name", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else if (isPresent) {
      toast("Group already exists", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    } else {
      setButtonLoader(true);
      postApi(
        "/quickSight/createGroup",
        reqBody,
        accessToken.AccessToken,
        organizationId,
        headers
      )
        .then((response) => {
          //       // Handle the response data here
          //       // setGroupList(response.data.data.GroupList);

          getTableDetails();

          setButtonLoader(false);
          if (response?.data?.Status === 201) {
            handleClose();
            setFormData({
              groupName: "",
              description: "",
            });
            toast("New Group added successfully", {
              type: "success",
              theme: "dark",
              autoClose: 2000,
            });
          } else if (response?.message.includes("already exists")) {
            toast("Group already exists", {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
          } else {
            toast(response?.message, {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
          }
        })

        .catch((error) => {
          // Handle any errors that occurred during the API call
          setButtonLoader(false);
          console.error("Error:", error?.message);
          toast(error.message, {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        });
    }
  };

  const handleDelete = (name, event) => {
    setLoaderDelete(true);
    event.preventDefault();
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };
    const reqBody = {
      groupName: name,
    };

    deleteApi(
      "/quickSight/deleteGroup",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((response) => {
        getTableDetails();

        handleClickDeleteClose();

        setLoaderDelete(false);

        response.data.Status === 200
          ? toast(" Group Deleted successfully", {
              type: "success",
              theme: "dark",
              autoClose: 2000,
            })
          : toast("Some error occured, Please try again", {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
        setLoaderDelete(false);
      });
  };
  const updateParentState = () => {
    setAddGroup(true);
  };

  // const handleReset = () => {};
  return (
    <div>
      {addGroup ? (
        <Paper elevation={2} sx={{ borderRadius: "5px" }}>
          <Box
            sx={{
              p: 4,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <Typography variant="h6" className="sub-header">
              Manage Group
            </Typography>

            <div
              className="top-header"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={handleOpen}
                sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
              >
                Add Group
              </Button>

              <div>
                <TextField
                  id="outlined-basic"
                  label="Search Group"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <DialogTitle>Add New Group</DialogTitle>
                  <DialogTitle>
                    <CloseIcon
                      onClick={handleClose}
                      className="pointerCursor"
                    />
                  </DialogTitle>
                </div>
                <DialogContent>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="groupName"
                      label={
                        <span>
                          Group name
                          <Typography component="span" sx={{ color: redColor }}>
                            *
                          </Typography>
                        </span>
                      }
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="groupName"
                      value={formData.groupName}
                      onChange={handleInputChange}
                    />

                    <TextField
                      multiline
                      rows={4}
                      margin="dense"
                      id="description"
                      label="Description"
                      fullWidth
                      variant="outlined"
                      type="text"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                    />
                  </form>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 3,
                  }}
                >
                  {buttonLoader ? (
                    <div className="loader"></div>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ backgroundColor: primaryColor1 }}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: primaryColor1 }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </DialogActions>
              </Dialog>
            </div>
            <DataGrid
              rows={filterMenuList()}
              columns={columns}
              getRowId={(row) => row.GroupName}
              initialState={{
                ...menuList.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 25]}
              disableRowSelectionOnClick
            />
          </Box>
          <Dialog
            open={openDelete}
            onClose={handleClickDeleteClose}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle>{"Delete Group"}</DialogTitle>
              <DialogTitle>
                <CloseIcon
                  onClick={handleClickDeleteClose}
                  className="pointerCursor"
                />
              </DialogTitle>
            </div>

            <DialogContent>
              <DialogContentText>
                {groupUserList.length === 0
                  ? `Are you sure you want to delete ${deleteId} ?`
                  : `Group selected to delete has users added to it. Are you sure you want to delete ${deleteId} ?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
              }}
            >
              {loaderDelete ? (
                <div className="loader"></div>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: whiteColor,
                      color: primaryColor1,
                      borderColor: primaryColor1,
                    }}
                    onClick={(event) => {
                      handleDelete(deleteId, event);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: primaryColor1 }}
                    onClick={handleClickDeleteClose}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </Paper>
      ) : (
        <Paper elevation={2} sx={{ borderRadius: "5px" }}>
          <Box
            sx={{
              p: 4,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <AddGroupUser
              groupId={groupId}
              desc={groupDesc}
              updateParentState={updateParentState}
            />
          </Box>
        </Paper>
      )}
      <ToastContainer />
    </div>
  );
}

export default ManageGroup;
