import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Grid,
  Paper,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { backgroundBlue, itemHover1 } from "../commonStyles/styles";
import { useLocation } from "react-router-dom";
import { selectBiLogin, currentUserData } from "../user/LoginSlice";
import axios from "axios";
import { routeName } from "../commonStrings/CommonStrings";
import Board from "./Board";
import QSearchBar from "./QSearchBar";
import Analytics from "./Analytics";
import "./analytics.scss";

const ResponsivePaper = ({ children }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        boxShadow: "none",
        borderRadius: 0,
        maxWidth: "100%", // Make menu responsive
      }}
    >
      {children}
    </Paper>
  );
};

const AnalyticsNavbar = ({ items, isInitialized }) => {
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [tempBreadCrumpItems, setTempBreadCrumpItems] = useState([]);
  const [reloadBoard, setReloadBoard] = useState(false);
  const [boardData, setBoardData] = useState("");
  const [dashboardId, setDashboardId] = useState("");
  const [sheetId, setSheetId] = useState("");
  const [topicId, setTopicId] = useState("");
  const [menuTypeBoard, setMenuTypeBoard] = useState("");
  const [customerToken, setCustomerToken] = useState({});
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const dispatch = useDispatch();
  const biUser = useSelector(selectBiLogin);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  useEffect(() => {
    if (localStorage.getItem("customerToken") !== null) {
      setCustomerToken(JSON.parse(localStorage.getItem("customerToken")));
    }
  }, [localStorage.getItem("customerToken")]);

  const handleBoardReload = () => {
    setReloadBoard((prevValue) => !prevValue);
  };

  const getDashboardDetails = (dashboardId) => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const loggedInUserName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const customerCode = biUserData?.CustomerToken?.CustomerCode;

    let cuCode;
    if (customerCode !== undefined && customerCode !== null) {
      cuCode = customerCode;
    } else if (
      localStorage.getItem("customerToken") !== null &&
      localStorage.getItem("customerToken") !== undefined
    ) {
      const customerToken = JSON.parse(localStorage.getItem("customerToken"));
      cuCode = customerToken?.CustomerCode;
    } else {
      cuCode = "DEMO_PAYER";
    }

    const entype = `${process.env.REACT_APP_TYPE}_`;
    const dbId = dashboardId
      ? `${entype}${cuCode?.toLowerCase()}_${dashboardId}`
      : "";

    const reqBody = {
      dashboardId: dbId,
      userName: loggedInUserName,
    };

    if (dbId !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/quickSight/dashboard`,
          reqBody,
          {
            headers: headers,
          }
        )
        .then((response) => {
          // Handle the response data here

          setBoardData(response.data.data.EmbedUrl);
        })
        .catch((error) => {
          // Handle any errors that occurred during the API call
          console.error("Error:", error);
        });
    }
  };

  const getQDetails = (topicId) => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

     const customerCode = biUserData?.CustomerToken?.CustomerCode;

    let cuCode;
    if (customerCode !== undefined && customerCode !== null) {
      cuCode = customerCode;
    } else if (
      localStorage.getItem("customerToken") !== null &&
      localStorage.getItem("customerToken") !== undefined
    ) {
      const customerToken = JSON.parse(localStorage.getItem("customerToken"));
      cuCode = customerToken?.CustomerCode;
    } else {
      cuCode = "DEMO_PAYER";
    }

    const loggedInUserName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const entype = `${process.env.REACT_APP_TYPE}_`;
    const topId = topicId ? `${entype}${cuCode?.toLowerCase()}_${topicId}` : "";

    const reqBody = {
      topicId: topId,
      userName: loggedInUserName,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/quickSight/qSearchBar`,
        reqBody,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // Handle the response data here

        setBoardData(response.data.data.EmbedUrl);

        // setMenuList(response.data.data.bi);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };

  const getAnalyticsDetails = (dashboardId, topicId) => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const loggedInUserName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const customerCode = biUserData?.CustomerToken?.CustomerCode;

    let cuCode;
    if (customerCode !== undefined && customerCode !== null) {
      cuCode = customerCode;
    } else if (
      localStorage.getItem("customerToken") !== null &&
      localStorage.getItem("customerToken") !== undefined
    ) {
      const customerToken = JSON.parse(localStorage.getItem("customerToken"));
      cuCode = customerToken?.CustomerCode;
    } else {
      cuCode = "DEMO_PAYER";
    }

    const entype = `${process.env.REACT_APP_TYPE}_`;
    const dbId = dashboardId
      ? `${entype}${cuCode?.toLowerCase()}_${dashboardId}`
      : "";
    const topId = topicId ? `${entype}${cuCode?.toLowerCase()}_${topicId}` : "";

    const reqBody = {
      dashboardId: dbId,
      topicId: topId,
      userName: loggedInUserName,
    };

    // console.log(reqBody);

    if (dbId !== "") {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/quickSight/analytics`,
          reqBody,
          {
            headers: headers,
          }
        )
        .then((response) => {

          // console.log(response.data.data);
          setBoardData(response.data.data);
          // console.log(boardData);
        })
        .catch((error) => {
          // Handle any errors that occurred during the API call
          console.error("Error:", error);
        });
    }
  };

  function findMenuByName(menu, targetName) {
    var tempDashboardId = "";
    var tempSheetId = "";

    items.map((e) => {
      if (e.MenuName === menu) {
        e.Children.map((submenu) => {
          if (submenu.MenuName === targetName) {
            tempDashboardId = submenu.DashboardId;
            tempSheetId = submenu.SheetId;
          }
        });
      }
    });

    return { dashboardId: tempDashboardId, sheetId: tempSheetId };
  }

  useEffect(() => {
    if (pathnames[0] === routeName) {
      const newCustomerToken = biUserData?.CustomerToken;
      setCustomerToken(newCustomerToken);
    }

    const homeMenu = findMenuByName("Summary", "Home");
    setSheetId(homeMenu.sheetId);
    setMenuTypeBoard("analytics");

    if (pathnames[0] === routeName) {
      if (isInitialized) {
        if (homeMenu !== "") {
          getDashboardDetails(homeMenu.dashboardId);
        }
      }
    } else {
      if (homeMenu !== "") {
        getDashboardDetails(homeMenu.dashboardId);
      }
    }
    // Call the function to find the "Home" menu under "Summary"

    setBreadcrumbItems(["Summary", "Home"]);
  }, [isInitialized]);

  const [popoverStack, setPopoverStack] = useState([]);
  const [anchorPositions, setAnchorPositions] = useState([]);

  const handleArrowClick = (event, item, breadcrumbSet, index) => {
    if (breadcrumbSet === "case1") {
      setTempBreadCrumpItems([item.MenuName]);
    }

    if (item.Children.length !== 0) {
      const parentRect = event.currentTarget.getBoundingClientRect();
      const parentRightPosition = parentRect.left + window.pageXOffset - 15;
      const parentTopPosition = parentRect.bottom + window.pageYOffset;

      const newPopoverStack = [...popoverStack];
      newPopoverStack.push(item.Children);
      setPopoverStack(newPopoverStack);

      const newAnchorPositions = [...anchorPositions];
      const rect = event.currentTarget.getBoundingClientRect();
      const rightPosition = rect.right + window.pageXOffset - 15;
      const topPosition = rect.top + window.pageYOffset;

      // Check if the right position goes beyond the screen width
      const screenWidth = window.innerWidth;
      const popoverWidth = 250; // Adjust this based on your popover width
      const adjustedLeftPosition =
        rightPosition + popoverWidth > screenWidth
          ? rightPosition - popoverWidth
          : rightPosition;

      newAnchorPositions.push({
        top: topPosition,
        left: adjustedLeftPosition,
      });
      if (breadcrumbSet === "case1") {
        // Position the first submenu popover below the menu item
        setAnchorPositions([
          { top: parentTopPosition, left: parentRightPosition },
        ]);
      } else {
        setAnchorPositions(newAnchorPositions);
      }

      if (breadcrumbSet !== "case1") {
        tempBreadCrumpItems.push(item.MenuName);
      }
      setSelectedButtonIndex(index);
    } else {
      if (item.MenuType === "analytics") {
        setBoardData('');
        setMenuTypeBoard(item.MenuType);
        setSheetId(item.SheetId);
        setDashboardId(item.DashboardId);
        setTopicId("");
        getDashboardDetails(item.DashboardId);
        handleBoardReload();
        handleClosePopover();
        tempBreadCrumpItems.push(item.MenuName);
        setBreadcrumbItems(tempBreadCrumpItems);
        seperateFunction();
        setSelectedButtonIndex(index);
      } else if (item.MenuType === "q") {
        setBoardData('');
        setMenuTypeBoard(item.MenuType);
        setSheetId("");
        setDashboardId("");
        setTopicId(item.TopicId);
        getQDetails(item.TopicId);
        handleBoardReload();
        handleClosePopover();
        tempBreadCrumpItems.push(item.MenuName);
        setBreadcrumbItems(tempBreadCrumpItems);
        seperateFunction();
        setSelectedButtonIndex(index);
      } else if (item.MenuType === "both") {
        setBoardData('');
        setMenuTypeBoard(item.MenuType);
        setSheetId(item.SheetId);
        setDashboardId(item.DashboardId);
        getAnalyticsDetails(item.DashboardId, item.TopicId);
        handleBoardReload();
        handleClosePopover();
        tempBreadCrumpItems.push(item.MenuName);
        setBreadcrumbItems(tempBreadCrumpItems);
        seperateFunction();
        setSelectedButtonIndex(index);
      }
    }
  };

  const seperateFunction = () => {};
  const handleClosePopover = () => {
    setPopoverStack([]);
    setAnchorPositions([]);
    setSelectedButtonIndex(null);
  };
  return (
    <>
      <AppBar
        position="sticky"
        elevation={1}
        sx={{ background: "white", color: "black" }}
      >
        <Toolbar
          sx={{
            "@media (min-width: 600px)": {
              minHeight: "24px", // For screen width >= 600px
            },
            backgroundColor: backgroundBlue,
          }}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item container xs justify="flex-end">
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <IconButton
                    onClick={(event) =>
                      handleArrowClick(event, item, "case1", index)
                    }
                    color="inherit"
                    aria-controls={`menu-${index}`}
                    aria-haspopup="true"
                    size="small"
                    sx={{
                      fontSize: "14px",
                      fontFamily: "Verdana",
                      borderRadius: 0,
                      paddingRight: "50px",
                      "&:hover": { backgroundColor: itemHover1 },
                      backgroundColor:
                        selectedButtonIndex === index ? itemHover1 : "inherit",
                    }}
                  >
                    {item.MenuName}{" "}
                    {item.Children.length > 0 ? <ArrowDropDown /> : ""}
                  </IconButton>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Toolbar>
        {popoverStack.map((popoverContent, index) => (
          <Menu
            key={index}
            open={true}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handleClosePopover}
            elevation={1}
            PaperComponent={Paper}
            style={{
              position: "absolute",
              top: anchorPositions[index]?.top - 20 || 0, // Decrease the top value
              left: anchorPositions[index]?.left || 0, // Keep the left value as is
            }}
            slotProps={{
              paper: {
                style: {
                  minWidth: 200, // Set the minimum width as needed
                  backgroundColor: itemHover1,
                  borderRadius: 2,
                },
              },
            }}
          >
            {popoverContent.map((subitem, subindex) => (
              <MenuItem
                alignItems="center"
                key={subindex}
                onClick={(event) => handleArrowClick(event, subitem, "case2")}
                sx={{
                  fontWeight: 100,
                  padding: "7px",
                  fontSize: "13px",
                  fontFamily: "Verdana",
                  backgroundColor: itemHover1,
                  "&:hover": { backgroundColor: backgroundBlue },
                }}
              >
                {subitem.MenuName}{" "}
                {subitem.Children.length > 0 ? <ArrowRight /> : ""}
              </MenuItem>
            ))}
          </Menu>
        ))}
      </AppBar>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          paddingLeft: "20px",
        }}
      >
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            <Link
              color="inherit"
              sx={{
                fontSize: "12px",
                // cursor: "pointer",
                marginRight: "0px",
                textDecoration: "none",
              }}
            >
              {item}
            </Link>
          </React.Fragment>
        ))}
      </Breadcrumbs>
      <div>
        <Box>
          <Paper elevation={1}>
            {boardData !== "" &&
              (menuTypeBoard === "analytics" ? (
                <Board
                  boardData={boardData}
                  sheetId={sheetId}
                  customerToken={customerToken}
                />
              ) : menuTypeBoard === "q" ? (
                <QSearchBar boardData={boardData} />
              ) : menuTypeBoard === "both" ? (
                <Analytics
                  boardData={boardData}
                  sheetId={sheetId}
                  customerToken={customerToken}
                />
              ) : null)}
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default AnalyticsNavbar;
