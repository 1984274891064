import React, { useEffect, useState } from "react";
import { useMediaQuery, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../navbar/Navbar";
import NavbarInner from "../navbarInner/NavbarInner";
import "./analytics.scss";
import "./loader.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { selectBiLogin, setBiLogin, currentUserData } from "../user/LoginSlice";
import axios from "axios";
import { routeName } from "../commonStrings/CommonStrings";
import AnalyticsNavbar from "./AnalyticsNavbar";

function Analytics1() {
  const [recValue, setRecValue] = useState(false);
  const [loaderFlag, setLoaderFlag] = useState(false);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const dispatch = useDispatch();
  const biUser = useSelector(selectBiLogin);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  /**
   * ! New API response variables
   */

  const [menuList, setMenuList] = useState([]);
  const [menuFlag, setMenuFlag] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const Loader = () => {
    return (
      <div className="spinner-outq">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // pathnames[0] === "bi";
    const messageHandler = (event) => {
      if (event.data !== "request_data") {
        setLoaderFlag(true);
        setIsInitialized(false);
        setRecValue(true);

        /**
         *
         * !API call for verification
         */

        if (event.data?.data.hasOwnProperty("UserId")) {
          var biReduxData = event.data?.data;

          dispatch(setBiLogin(biReduxData));
          setIsInitialized(true);

          setLoaderFlag(false);
        } else {
          dispatch(setBiLogin({}));
          setLoaderFlag(true);
          setIsInitialized(false);
        }
      } else {
        pathnames[0] === "bi" ? setLoaderFlag(true) : setLoaderFlag(false);
      }
    };

    if (pathnames[0] === routeName) {
      if (recValue === false) {
        window.addEventListener("message", messageHandler);
        window.parent.postMessage("request_data", "*");
      }

      return () => {
        window.removeEventListener("message", messageHandler);
      };
    } else {
    }
  }, [recValue]);

  const getMenuList = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;

    const organizationId =
      pathnames[0] === routeName
        ? //  "95145b12-447b-4716-b6c8-040361d266f0"
          biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const userName =
      pathnames[0] === routeName ? biUserData?.UserName : userData?.UserName;

    const reqBody = {
      userName: userName,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/role/menuList`,
        reqBody,
        { headers: headers }
      )
      .then((response) => {
        // Handle the response data here

        setMenuList(response.data.data.bi);
        setMenuFlag(false);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    setMenuFlag(true);
    if (pathnames[0] === routeName) {
      if (isInitialized) {
        getMenuList();
      }
    } else {
      getMenuList();
    }
  }, [isInitialized]);

  const theme = createTheme(); // Create a default theme
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {loaderFlag ? (
        <Loader />
      ) : (
        <ThemeProvider theme={theme}>
          <div>
            {pathnames[0] === "bi" ? "" : <Navbar />}
            {pathnames[0] === "bi" ? (
              ""
            ) : (
              <NavbarInner currentLink="analytics" />
            )}

            <Divider />
            {pathnames[0] === "bi" && pathnames[1] === "analytics" ? (
              menuFlag ? (
                <Loader />
              ) : (
                <AnalyticsNavbar
                  items={menuList}
                  isInitialized={isInitialized}
                />
              )
            ) : menuFlag ? (
              <Loader />
            ) : (
              <AnalyticsNavbar items={menuList} isInitialized={isInitialized} />
            )}
          </div>
        </ThemeProvider>
      )}
    </div>
  );
}

export default Analytics1;
