/* eslint-disable */
import { Box, Paper } from "@mui/material";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import React, { useEffect, useState } from "react";

function updateValue(param) {
  if (Array.isArray(param.Values) && param.Values.some(value => value.toString().toLowerCase().includes('all') && value.length === 3)) {
      return { ...param, Values: ['ALL_VALUES'] };
  }
  return param;
}

function Board({ boardData, sheetId, customerToken }) {
  // const [dashboard, setDashboard] = React.useState(false);
  const loadDashboard = async (boardData, customerToken, bool) => {
    if (boardData === "" || boardData === undefined) {
      return;
    }

    if (sheetId === "" || sheetId === undefined) {
      return;
    }

    if (customerToken === "" || customerToken === undefined) {
      return;
    }

    if (
      localStorage.getItem("ParamCustomerCode") === null ||
      localStorage.getItem("ParamCustomerCode") === undefined
    ) {
      if (
        customerToken?.ParamCustomerCode !== null ||
        customerToken?.ParamCustomerCode !== undefined
      ) {
        localStorage.setItem(
          "ParamCustomerCode",
          customerToken?.ParamCustomerCode
        );
      } else {
        // console.log("ParamCustomerCodeCook is null");
      }
    }

    // console.log(
    //   "ParamCustomerCodeCook",
    //   localStorage.getItem("ParamCustomerCode")
    // );

    let embeddedDashboardExperience;
    let setId = false;
    const embeddingContext = await createEmbeddingContext();
    const { embedDashboard } = embeddingContext;
    const containerDiv = document.getElementById("dashboardContainer");
    containerDiv.innerHTML = "";

    const options = {
      parameters: [
        {
          Name: "paramCustomerCode",
          Values: [customerToken?.ParamCustomerCode ?? "DEMO_PAYER"],
        },
        {
          Name: "paramL1Label",
          Values: [customerToken?.ParamL1Label ?? "Level1"],
        },
        {
          Name: "paramL2Label",
          Values: [customerToken?.ParamL2Label ?? "Level2"],
        },
        {
          Name: "paramL3Label",
          Values: [customerToken?.ParamL3Label ?? "Level3"],
        },
        {
          Name: "paramL4Label",
          Values: [customerToken?.ParamL4Label ?? "Level4"],
        },
        {
          Name: "paramL5Label",
          Values: [customerToken?.ParamL5Label ?? "Level5"],
        },
      ],
      sheetOptions: {
        initialSheetId: sheetId,
        singleSheet: true,
        emitSizeChangedEventOnSheetChange: true,
      },
      toolbarOptions: {
        export: true,
        undoRedo: true,
        reset: true,
        bookmarks: false,
      },
      onMessage: async (messageEvent, experienceMetadata) => {
        // console.log(
        //   "Message received from the experience:",
        //   messageEvent.eventName
        // );
        // console.log("Message received from the experience:", messageEvent.message);
        switch (messageEvent.eventName) {
          case "CONTENT_LOADED": {
            //// console.log("All visuals are loaded. The title of the document:", messageEvent.message.title);
            break;
          }
          case "ERROR_OCCURRED": {
            // console.log("Error occurred while rendering the experience. Error code:", messageEvent.message.errorCode);
            break;
          }
          case "PARAMETERS_CHANGED": {
            // console.log(
            //   "Parameters changed. Changed parameters:",
            //   messageEvent.message.changedParameters
            // );

            embeddedDashboardExperience.getParameters().then((parameters) => {
              const paramCustomerCodeObject = parameters.find(
                (param) => param.Name === "paramCustomerCode"
              );

              if (
                paramCustomerCodeObject &&
                paramCustomerCodeObject.Values.length > 0
              ) {
                const paramCustomerCodeValue =
                  paramCustomerCodeObject.Values[0];

                if (paramCustomerCodeValue === "MASTER") {
                  setId = true;
                  window.sourceDashboardParameters = null;
                  embeddedDashboardExperience.setParameters([
                    {
                      Name: "paramCustomerCode",
                      Values: [
                        customerToken?.ParamCustomerCode ?? "DEMO_PAYER",
                      ],
                    },
                    {
                      Name: "paramL1Label",
                      Values: [customerToken?.ParamL1Label ?? "Level1"],
                    },
                    {
                      Name: "paramL2Label",
                      Values: [customerToken?.ParamL2Label ?? "Level2"],
                    },
                    {
                      Name: "paramL3Label",
                      Values: [customerToken?.ParamL3Label ?? "Level3"],
                    },
                    {
                      Name: "paramL4Label",
                      Values: [customerToken?.ParamL4Label ?? "Level4"],
                    },
                    {
                      Name: "paramL5Label",
                      Values: [customerToken?.ParamL5Label ?? "Level5"],
                    },
                    { Name: "paramLevel1", Values: "ALL_VALUES" },
                    { Name: "paramLevel2", Values: "ALL_VALUES" },
                    { Name: "paramLevel3", Values: "ALL_VALUES" },
                    { Name: "paramLevel4", Values: "ALL_VALUES" },
                    { Name: "paramLevel5", Values: "ALL_VALUES" },
                  ]);
                } else if (
                  localStorage.getItem("ParamCustomerCode") !==
                    customerToken?.ParamCustomerCode &&
                  customerToken?.ParamCustomerCode !== null &&
                  customerToken?.ParamCustomerCode !== undefined
                ) {
                  embeddedDashboardExperience.setParameters([
                    {
                      Name: "paramCustomerCode",
                      Values: [
                        customerToken?.ParamCustomerCode ?? "DEMO_PAYER",
                      ],
                    },
                    {
                      Name: "paramL1Label",
                      Values: [customerToken?.ParamL1Label ?? "Level1"],
                    },
                    {
                      Name: "paramL2Label",
                      Values: [customerToken?.ParamL2Label ?? "Level2"],
                    },
                    {
                      Name: "paramL3Label",
                      Values: [customerToken?.ParamL3Label ?? "Level3"],
                    },
                    {
                      Name: "paramL4Label",
                      Values: [customerToken?.ParamL4Label ?? "Level4"],
                    },
                    {
                      Name: "paramL5Label",
                      Values: [customerToken?.ParamL5Label ?? "Level5"],
                    },
                    { Name: "paramLevel1", Values: "ALL_VALUES" },
                    { Name: "paramLevel2", Values: "ALL_VALUES" },
                    { Name: "paramLevel3", Values: "ALL_VALUES" },
                    { Name: "paramLevel4", Values: "ALL_VALUES" },
                    { Name: "paramLevel5", Values: "ALL_VALUES" },
                  ]);

                  window.sourceDashboardParameters = null;
                  setId = true;

                  localStorage.setItem(
                    "ParamCustomerCode",
                    customerToken?.ParamCustomerCode
                  );
                } else {
                  if (window.sourceDashboardParameters && setId === false) {
                    setId = true;
                    embeddedDashboardExperience.setParameters(
                      window.sourceDashboardParameters
                    );
                    // console.log(
                    //   "window.sourceDashboardParameters===SET",
                    //   window.sourceDashboardParameters
                    // );
                  }
                }

                window.sourceDashboardParameters = parameters
                  .filter((param) =>
                    [
                      "paramLevel1",
                      "paramLevel2",
                      "paramLevel3",
                      "paramLevel5",
                      "paramLevel5",
                    ].includes(param.Name)
                  )
                  .map(updateValue);

                // console.log(
                //   "window.sourceDashboardParameters===GET",
                //   window.sourceDashboardParameters
                // );
              }
            });

            break;
          }
          case "SELECTED_SHEET_CHANGED": {
            // console.log(
            //   "Selected sheet changed. Selected sheet:",
            //   messageEvent.message.selectedSheet
            // );

            break;
          }
          case "SIZE_CHANGED": {
            //// console.log("Size changed. New dimensions:", messageEvent.message);
            break;
          }
          case "MODAL_OPENED": {
            window.scrollTo({
              top: 0, // iframe top position
            });
            break;
          }
        }
      },
    };

    const frameOptions = {
      url: boardData,
      container: containerDiv,
      resizeHeightOnSizeChangedEvent: true,
      onChange: (changeEvent, metadata) => {
        // eslint-disable-next-line default-case
        // console.log(
        //   "Change received from the experience:",
        //   changeEvent.eventName
        // );
         // console.log("Change received from the experience:", changeEvent.message);
        switch (changeEvent.eventName) {
          case "FRAME_MOUNTED": {
            setId = false;
            break;
          }
          case "FRAME_LOADED": {
            //// console.log("Do something when the experience frame is loaded.");
            break;
          }
        }
      },
    };

    try {
      embeddedDashboardExperience = await embedDashboard(frameOptions, options);
    } catch (error) {
      console.error("Error embedding dashboard:", error);
    }
  };

  useEffect(() => {
    // console.log("customerToken", customerToken);
    if (boardData !== "" && boardData !== undefined) {
      //setDashboard(true);
      // console.log("boardData::::>>>>", sheetId);
      // console.log("customerToken::::>>>>", customerToken?.ParamCustomerCode);
      loadDashboard(boardData, customerToken, false);
    }
  }, [boardData, sheetId, customerToken]);

  return (
    <div>
      <Box
       >
        <Paper
          elevation={1}
          sx={{
            borderRadius: "5px",
          }}
        >
          <div id="dashboardContainer"></div>
        </Paper>
      </Box>
    </div>
  );
}

export default Board;
