import React, { useState, useEffect } from "react";
import { Tree, Select } from "antd";
import { Box, Paper, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { currentUserList } from "../AdminSlice";
import { jsonTransform } from "../helpers/UserListHelper";
import { jsonTransformGroup } from "../helpers/GroupNameHelper";
import { DownOutlined } from "@ant-design/icons";
import "./userPermission.scss";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { selectBiLogin, currentUserData } from "../../user/LoginSlice";
import { routeName } from "../../commonStrings/CommonStrings";
import axios from "axios";
import { jsonTransformMenuRoleLevel } from "../helpers/RoleLevelMenuListHelper";

function GroupPermissions() {
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [groupList, setGroupList] = useState([]);

  const userListG = useSelector(currentUserList);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const dispatch = useDispatch();
  const biUser = useSelector(selectBiLogin);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  const handleToggleMenu = (clickedNode) => {
    // Create a copy of the data to avoid modifying the state directly.
    const newData = [...treeData];

    // Find the clicked node in the data.
    const findNode = (nodes) => {
      for (const node of nodes) {
        if (node.RoleLevelId === clickedNode.RoleLevelId) {
          node.Toggle = !node.Toggle; // Toggle the 'Toggle' property.
        } else if (node.Children && node.Children.length) {
          findNode(node.Children);
        }
      }
    };

    findNode(newData);

    setTreeData(newData); // Update the state with the modified data.

    const reqBody = {
      type: "group",
      menuId: clickedNode.RoleLevelId,
      userName: selectedUser,
      status: clickedNode.Toggle ? 2 : 1,
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/security/role/updateStatus`,
        reqBody
      )
      .then((res) => {
        if (res.status === 200) {
          onChange(selectedUser);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getUserList = () => {
    const newRes = jsonTransform(userListG);

    setUserList(newRes);
  };

  const getGroupList = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/quickSight/groupList`, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here
        const newRes = jsonTransformGroup(response.data.data.GroupList);

        setGroupList(newRes);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };

  const getTreeData = () => {
    /**
     * !API call to fetch tree data
     */
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/security/role/get`)
      .then((res) => {
        if (res?.data?.data?.length === 0) {
          const defaultNode = {
            RoleLevelTitle: "Cqwik", // Set the title for the default node
            RoleLevelId: "default-key", // Set a unique key for the default node
            RoleLevelIcon: true,
            Children: [], // You can start with an empty children array
          };

          // Set the treeData state with the default node

          setTreeData([defaultNode]);
        } else {
          setTreeData(res.data.data);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getMenuList = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

    const organizationId =
      pathnames[0] === routeName
        ? //  "95145b12-447b-4716-b6c8-040361d266f0"
          biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/menu/list`, { headers: headers })
      .then((response) => {
        // Handle the response data here

        var newMenuList = jsonTransformMenuRoleLevel(response.data.data.bi);

        setMenuList(newMenuList);
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  };
  useEffect(() => {
    getMenuList();
    getGroupList();
  }, []);

  const onSelect = (selectedKeys, info) => {};

  const handleTreeSave = () => {};
  const onChange = (value) => {
    setSelectedUser(value);
    const defaultNode = {
      RoleLevelTitle: value, // Set the title for the default node
      RoleLevelId: value, // Set a unique key for the default node
      RoleLevelIcon: false,
      Children: [], // You can start with an empty children array
    };

    const reqBody = {
      userName: value,
      type: "group",
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/security/role/get`, reqBody)
      .then((res) => {
        const temp = res.data.data.bi;
        defaultNode.Children = temp;
        setTreeData([defaultNode]);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const onSearch = (value) => {};

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const renderTreeNodes = (data) => {
    return data.map((node) => (
      <Tree.TreeNode
        title={
          <div className="tree-node-content">
            {node?.RoleLevelIcon ? (
              <label className="switch">
                <input
                  type="checkbox"
                  checked={node.Toggle}
                  onChange={() => handleToggleMenu(node)}
                />
                <span className="slider" />
              </label>
            ) : null}
            <span className="checkbox-title">{node.RoleLevelTitle}</span>
          </div>
        }
        key={node?.RoleLevelId}
      >
        {node?.Children?.length > 0 && renderTreeNodes(node?.Children)}
      </Tree.TreeNode>
    ));
  };

  return (
    <Paper elevation={1} sx={{ borderRadius: "5px" }}>
      <Box
        sx={{
          p: 4,
          alignItems: "center",
        }}
      >
        <Typography variant="h6" className="sub-header">
          Group Permissions
        </Typography>
        <div className="top-header">
          <Box sx={{ p: 1, width: "100%" }}>
            <Select
              style={{ width: "50%" }}
              showSearch
              placeholder="Select a group"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={groupList}
            />
          </Box>
        </div>
        <Box sx={{ p: 1, width: "80%" }}>
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandAll={true}
          >
            {renderTreeNodes(treeData)}
          </Tree>
        </Box>
      </Box>
    </Paper>
  );
}

export default GroupPermissions;
