import { createSlice } from "@reduxjs/toolkit";

const initialState = { routePath: "", userListData: [], reloadRoute: true };
// const initialState = [];
export const AdminSlice = createSlice({
  name: "routePath",
  initialState,

  reducers: {
    savePath: (state, action) => {
      state.routePath = action.payload;
    },
    userListGlobal: (state, action) => {
      state.userListData = action.payload;
    },

    reload: (state, action) => {
      state.reloadRoute = action.payload;
    },
  },
});
export const { savePath, userListGlobal, reload } = AdminSlice.actions;

export const currentPath = (state) => state.routePath.routePath;
export const currentUserList = (state) => state.routePath.userListData;
export const reloadPath = (state) => state.routePath.reloadRoute;

export default AdminSlice.reducer;
