/* eslint-disable */
import { Box, Paper } from "@mui/material";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import React, { useEffect, useState } from "react";

function QSearchBar({ boardData }) {
  const loadQSearchBar = async (boardData) => {
    if (boardData === "" || boardData === undefined) {
      return;
    }

    let embeddedDashboardExperience;
    let setId = false;

    const embeddingContext = await createEmbeddingContext();
    const { embedGenerativeQnA } = embeddingContext;

    const containerDiv = document.getElementById("qSearchBarContainer");
    containerDiv.innerHTML = "";

    const frameOptions = {
      url: boardData,
      container: containerDiv,
      height: "50px",
      width: "800px",
      onChange: (changeEvent, metadata) => {
        switch (changeEvent.eventName) {
          case "FRAME_MOUNTED": {
            // console.log("Do something when the experience frame is mounted.");
            break;
          }
          case "FRAME_LOADED": {
            // console.log("Do something when the experience frame is loaded.");
            break;
          }
        }
      },
    };

    const contentOptions = {
      panelOptions: {
        panelType: "SEARCH_BAR",
        title: "Custom Title",
        showQIcon: false,
      },
      showTopicName: false,
      showPinboard: false,
      allowTopicSelection: false,
      allowFullscreen: true,
      searchPlaceholderText: "Ask QMX about your data",
      onMessage: async (messageEvent, experienceMetadata) => {
        switch (messageEvent.eventName) {
          case "Q_SEARCH_OPENED": {
            // console.log("Do something when SEARCH_BAR type panel is expanded");
            break;
          }
          case "Q_SEARCH_FOCUSED": {
            // console.log("Do something when SEARCH_BAR type panel is focused");
            break;
          }
          case "Q_SEARCH_CLOSED": {
            // console.log("Do something when SEARCH_BAR type panel is collapsed");
            break;
          }
          case "Q_PANEL_ENTERED_FULLSCREEN": {
            // console.log(
            //   "Do something when the experience enters full screen mode"
            // );
            break;
          }
          case "Q_PANEL_EXITED_FULLSCREEN": {
            // console.log(
            //   "Do something when the experience exits full screen mode"
            // );
            break;
          }
          case "CONTENT_LOADED": {
            // console.log("Do something when the experience is loaded");
            break;
          }
          case "ERROR_OCCURRED": {
            // console.log("Do something when an error occurs.");
            break;
          }
        }
      },
    };

    try {
      embeddedDashboardExperience = await embedGenerativeQnA(
        frameOptions,
        contentOptions
      );
    } catch (error) {
      console.error("Error embedding dashboard:", error);
    }
  };

  useEffect(() => {
    if (boardData !== "" && boardData !== undefined) {
      loadQSearchBar(boardData);
    }
  }, [boardData]);

  return (
    <div>
      <Box>
        <div id="qSearchBarContainer" style={{ textAlign: "center" }}></div>
      </Box>
    </div>
  );
}

export default QSearchBar;
