import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { primaryColor1, whiteColor } from "../commonStyles/styles";
import { routeName } from "../commonStrings/CommonStrings";
import { currentUserData, selectBiLogin } from "../user/LoginSlice";
import axios from "axios";

function AwsConfiguration() {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    awsAccountId: "",
    awsRegion: "",
    // emailId: "",
    awsCognitoId: "",
    awsAccessKey: "",
  });

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const regionOptions = [
    { value: "us-east-1", label: "US East 1" },
    { value: "us-west-1", label: "US West 1" },
    { value: "eu-central-1", label: "EU Central 1" },
    // Add more region options as needed
  ];

  function getAwsDetails() {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/aws/awsDetails`, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here
        setFormData({
          awsAccountId: response.data.data.AwsAccountId,
          awsCognitoId: response.data.data.CognitoUserPoolId,
          awsRegion: response.data.data.AwsDetailsRegion,
          awsAccessKey: response.data.data.AwsDetailsAccessKeyId,
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  }

  useEffect(() => {
    const awsDetails = getAwsDetails();
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReset = () => {
    setFormData({
      userName: "",
      emailId: "",
      password: "",
      region: "",
    });
    setShowPassword(false);
  };
  return (
    <Paper elevation={1} sx={{ borderRadius: "5px", height: "84vh" }}>
      <Box
        sx={{
          p: 4,
          alignItems: "center",
          "& .super-app-theme--header": {
            backgroundColor: primaryColor1,
            color: whiteColor,
          },
        }}
      >
        <Typography variant="h6" className="sub-header">
          AWS Configuration
        </Typography>
        <div
          className="top-header"
          style={{ display: "flex", alignItems: "center" }}
        >
          <form>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                  size="small"
                  margin="dense"
                  id="aws-account-id"
                  label="AWS Account Id"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="awsAccountId"
                  value={formData.awsAccountId}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="dense"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                >
                  <InputLabel id="region-label">AWS Region</InputLabel>
                  <Select
                    labelId="region-label"
                    id="aws-region"
                    value={formData.awsRegion}
                    label="AWS Region"
                    name="awsRegion"
                    onChange={handleInputChange}
                  >
                    {regionOptions.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  size="small"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                  margin="dense"
                  id="aws-cognito-id"
                  label="AWS Cognito Id"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="awsCognitoId"
                  value={formData.awsCognitoId}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: primaryColor1, // Replace "your_custom_color" with your desired color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: primaryColor1, // Replace "your_custom_color" with your desired color
                      },
                    },
                  }}
                  size="small"
                  margin="dense"
                  id="aws-accesskey"
                  label="AWS Access Key"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="awsAccessKey"
                  value={formData.awsAccessKey}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </Box>
    </Paper>
  );
}

export default AwsConfiguration;
