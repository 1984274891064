import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import persistConfig from "./persistConfig";
import LoginReducer from "../components/user/LoginSlice";
import AdminSlice from "../components/admin/AdminSlice";

const rootReducer = combineReducers({
  loginU: LoginReducer,
  routePath: AdminSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
