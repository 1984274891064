import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userSelect: "",
  userName: "",
  userData: {},
  biLogin: {},
  userToken: {},
  biUserToken: {},
};
// const initialState = [];
export const LoginSlice = createSlice({
  name: "loginU",
  initialState,

  reducers: {
    loginUser: (state, action) => {
      state.userSelect = action.payload;
    },

    loginUserName: (state, action) => {
      state.userName = action.payload;
    },
    loginUserData: (state, action) => {
      state.userData = action.payload;
    },
    setBiLogin: (state, action) => {
      state.biLogin = action.payload;
    },

    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setBiUserToken: (state, action) => {
      state.biUserToken = action.payload;
    },
  },
});
export const {
  loginUser,
  loginUserName,
  loginUserData,
  setBiLogin,
  setUserToken,
  setBiUserToken,
} = LoginSlice.actions;

export const selectUser = (state) => state.loginU.userSelect;
export const currentUserName = (state) => state.loginU.userName;
export const currentUserData = (state) => state.loginU.userData;

export const selectBiLogin = (state) => state.loginU.biLogin;

export const currentUserToken = (state) => state.loginU.biLogin;

export const currentBiUserToken = (state) => state.loginU.biLogin;

export default LoginSlice.reducer;
