import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { VscTrash } from "react-icons/vsc";
import { BsPlusSquare } from "react-icons/bs";
import { LiaCircleSolid } from "react-icons/lia";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { MdOutlineArrowRight } from "react-icons/md";
import AddNodeModal from "./AddNodeModal";
import EditModal2 from "./EditModal";
import { createNode, addNode, removeNode, updateNode } from "./TreeUtils";
import "./Node.css";
import { selectBiLogin, currentUserData } from "../../../user/LoginSlice";
import axios from "axios";
import { getApi } from "../../../api/Api";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { routeName } from "../../../commonStrings/CommonStrings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { primaryColor1 } from "../../../commonStyles/styles";
// ... (your Data definition)

function Node({
  MenuId,
  MenuName,
  DashboardId,
  SheetId,
  TopicId,
  Children,
  isMenuParent,
  setMenu,
  menu,
  menuName,
  setMenuName,
  MenuType,
}) {
  const [open, setOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  const dataString = JSON.stringify("");

  localStorage.setItem("menu", dataString);

  const toggleMinimized = () => {
    setIsMinimized(!isMinimized);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModal1 = () => {
    setModalIsOpenEdit(true);
  };

  const closeModal1 = () => {
    setModalIsOpenEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddChild = (
    nodeTitle,
    isMenuParent,
    DashboardId,
    SheetId,
    topicId
  ) => {
    const newNode = createNode(
      uuidv4(),
      nodeTitle,
      isMenuParent,
      DashboardId,
      SheetId,
      MenuId,
      topicId
    );
    const updatedMenu = addNode(menu, MenuId, newNode);
    setMenu(updatedMenu);
    closeModal();
  };

  const handleSave = ({
    MenuName,
    isMenuParent,
    DashboardId,
    SheetId,
    topicId,
    valueRadio,
  }) => {
    const updatedData = updateNode(
      menu,
      MenuId,
      MenuName,
      isMenuParent,
      DashboardId,
      SheetId,
      topicId
    );

    setMenu(updatedData);
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

    const organizationId =
      pathnames[0] === routeName
        ? //  "95145b12-447b-4716-b6c8-040361d266f0"
          biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };

    const reqBody = {
      MenuId: MenuId,
      ParentMenuId: null, //"check",
      isMenuParent: isMenuParent,
      MenuName: MenuName,
      DashboardId: DashboardId,
      SheetId: SheetId,
      TopicId: topicId,
      MenuType: valueRadio === 1 ? "analytics" : valueRadio === 2 ? "q" : 0,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/menu/update`, reqBody, {
        headers,
      })
      .then((res) => {})
      .catch((err) => {
        // console.log(err);
      });
    closeModal1();
  };

  const handleEditClick = (MenuName, isMenuParent, DashboardId, SheetId) => {
    setEditData({
      MenuName,
      isMenuParent,
      DashboardId,
      SheetId,
      MenuType,
      TopicId,
    });
    openModal1();
  };

  const confirmDeleteNode = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    handleRemove();
    setOpen(false);
  };

  const handleRemove = async () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token

    const organizationId =
      pathnames[0] === routeName
        ? //  "95145b12-447b-4716-b6c8-040361d266f0"
          biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const defaultHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };
    const payload = {
      MenuId: MenuId,
    };

    if (isMenuParent === true && Children.length !== 0) {
      toast(
        "Deletion of Menu with Submenu restricted. Remove all submenus first",
        {
          type: "error",
          theme: "dark",
          autoClose: 2000,
        }
      );
    } else {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_BASE_URL}/menu/delete`,
          method: "POST",
          data: payload,
          headers: { ...defaultHeaders }, // Merge default headers with the passed headers
        });

        // Return the response data
        const updatedMenu = removeNode(menu, MenuId);
        setMenu(updatedMenu);

        toast("Deleted Node successfully", {
          type: "success",
          theme: "dark",
          autoClose: 2000,
        });
      } catch (error) {
        // Handle API call errors
        console.error("API Error:", error.response);

        // throw error; // Re-throw the error to handle it in the component
      }
    }
  };

  const handleSaveMenuName = (newName) => {
    setMenuName(newName);
    setIsEditingName(false);
    localStorage.setItem("menuName", newName);
  };

  // ... (the rest of your JSX return)
  useEffect(() => {
    const fetchMenuList = async () => {
      try {
        const accessToken =
          pathnames[0] === routeName
            ? biUserData?.AuthKeys
            : userData?.AuthKeys; // Replace with your actual access token

        const organizationId =
          pathnames[0] === routeName
            ? //  "95145b12-447b-4716-b6c8-040361d266f0"
              biUserData?.Organization.OrganizationId
            : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

        const data = await getApi(
          "/menu/list",
          null,
          accessToken,
          organizationId
        );
        setMenu(data.data.bi); // assuming the data returned from the API is the menu you want to set
      } catch (error) {
        console.error("Error fetching menu list:", error);
      }
    };

    fetchMenuList();
  }, []);

  return (
    <div className={`node-container ${MenuId === null ? "root-node" : ""}`}>
      {MenuId === null && (
        <div className="menu-name">
          {!isEditingName ? (
            <>
              &nbsp;
              <LiaCircleSolid className="circle-icon" />
              <span>&nbsp; &nbsp; &nbsp; {menuName} &nbsp;</span>
            </>
          ) : (
            <>
              <input
                type="text"
                value={menuName}
                onChange={(e) => setMenuName(e.target.value)}
              />
              <button onClick={() => handleSaveMenuName(menuName)}>Save</button>
            </>
          )}
        </div>
      )}
      {MenuId !== null && (
        <div className="node-header">
          <div className="horizontal-line"></div>
          <span onClick={toggleMinimized} className="minimize-icon">
            {isMinimized ? (
              <MdOutlineArrowRight className="minimize-icon1" />
            ) : (
              <MdOutlineArrowDropDown className="minimize-icon1" />
            )}
          </span>
          <span className="node-title" style={{ font: "message-box" }}>
            {MenuName}
          </span>
          <span style={{ paddingRight: "10px" }}></span>
          <VscTrash onClick={confirmDeleteNode} className="trash-icon" />
          <span style={{ paddingRight: "10px" }}></span>
          <FiEdit
            onClick={() =>
              handleEditClick(MenuName, isMenuParent, DashboardId, SheetId)
            }
            className="edit-icon"
          />
        </div>
      )}
      {!isMinimized && (
        <>
          {DashboardId && (
            <div className="node-content" id="dashboard">
              &nbsp;&nbsp; Dashboard ID: "{DashboardId}"
              <div className="horizontal-line1"></div>
            </div>
          )}
          {SheetId && (
            <div className="node-content" id="sheet">
              &nbsp;&nbsp; Sheet ID: "{SheetId}"
              <div className="horizontal-line1"></div>
            </div>
          )}
          {TopicId && (
            <div className="node-content" id="sheet">
              &nbsp;&nbsp; Topic ID: "{TopicId}"
              <div className="horizontal-line1"></div>
            </div>
          )}
        </>
      )}
      {!isMinimized &&
        Children?.map((child, index) => (
          <Node
            key={child.MenuId}
            {...child}
            setMenu={setMenu}
            menu={menu}
            menuName={menuName}
            setMenuName={setMenuName}
          />
        ))}
      <EditModal2
        show={modalIsOpenEdit}
        handleClose={closeModal1}
        initialData={editData}
        handleSave={handleSave}
      />
      <AddNodeModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onAddNode={handleAddChild}
        ParentMenuId={MenuId}
      />
      {MenuId === null && (
        <div className="add-root-node-button">
          <div className="horizontal-line3"></div>

          <BsPlusSquare
            style={{ height: "12px" }}
            color="grey"
            onClick={openModal}
          />
        </div>
      )}
      {isMenuParent && MenuId !== null && (
        <div className="add-terminal-node-button">
          <div className="horizontal-line2"></div>
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          <BsPlusSquare
            style={{ height: "12px" }}
            color="grey"
            onClick={openModal}
          />
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Delete Node</DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={handleClose} className="pointerCursor" />
          </DialogTitle>
        </div>
        <DialogContent>
          {` Are you sure you want to delete the node ${MenuName}?`}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          <Button
            variant="contained"
            onClick={handleDelete}
            sx={{ backgroundColor: primaryColor1 }}
          >
            Delete
          </Button>

          <Button
            variant="contained"
            sx={{ backgroundColor: primaryColor1 }}
            onClick={() => {
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}

export default Node;
