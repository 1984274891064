import React from "react";
import Box from "@mui/material/Box";
import Board from "./Board";
import QSearchBar from "./QSearchBar";
import { useSelector } from "react-redux";
import { routeName } from "../commonStrings/CommonStrings";
import { useLocation } from "react-router-dom";
import { selectBiLogin, currentUserData } from "../user/LoginSlice";

function Analytics({ boardData, sheetId, customerToken }) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const userData = useSelector(currentUserData);
  const biUserData = useSelector(selectBiLogin);

  const QPermission =
    pathnames[0] === routeName
      ? biUserData?.QPermission
      : userData?.QPermission;

  return (
    <div style={{ overflowY: "auto" }}>
      {QPermission === 1 && (
        <>
          {boardData.GenerativeQ?.EmbedUrl && (
            <Box
              sx={{
                position: "absolute",
                left: "30%",
              }}
            >
              <QSearchBar boardData={boardData.GenerativeQ.EmbedUrl} />
            </Box>
          )}
          <div
            style={{
              marginTop: boardData.GenerativeQ?.EmbedUrl ? "50px" : "0",
            }}
          >
            <Board
              boardData={boardData.Dashboard.EmbedUrl}
              sheetId={sheetId}
              customerToken={customerToken}
            />
          </div>
        </>
      )}
      {QPermission === 2 ? (
        <Board
          boardData={boardData.Dashboard.EmbedUrl}
          sheetId={sheetId}
          customerToken={customerToken}
        />
      ) : null}
    </div>
  );
}

export default Analytics;
