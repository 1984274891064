export const nullHandlerHelper = (data) => {
  const updatedData = data.map((item) => {
    // Use a loop or object destructuring to handle all properties
    const updatedItem = { ...item };

    // Iterate through the object properties and replace null values with "null"
    for (const key in updatedItem) {
      if (key === "username") {
        if (updatedItem[key] === null || updatedItem[key] === "null") {
          updatedItem[key] = null;
        } else {
          updatedItem[key] = item[key];
        }
      } else if (key === "groupname") {
        if (updatedItem[key] === null || updatedItem[key] === "null") {
          updatedItem[key] = null;
        } else {
          updatedItem[key] = item[key];
        }
      } else {
        if (updatedItem[key] === null) {
          updatedItem[key] = "All";
        }
      }
    }

    return updatedItem;
  });
  return updatedData;
};
