import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "../navbar/Navbar";
import NavbarInner from "../navbarInner/NavbarInner";
import { useLocation } from "react-router-dom";
import { primaryColor1, whiteColor } from "../commonStyles/styles";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import "./s3manager.scss";
import { routeName } from "../commonStrings/CommonStrings";
import FolderIcon from "../../assets/Folder.png";
import FileIcon from "../../assets/File.png";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { currentUserData, selectBiLogin } from "../user/LoginSlice";
import AWS from "aws-sdk";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApi } from "../api/Api";
import { Breadcrumb } from "antd";

function convertToBytes(sizeStr) {
  const units = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  const [size, unit] = sizeStr?.split(" ");
  return parseFloat(size) * (units[unit] || 1);
}

// Custom comparator for size
function sizeComparator(v1, v2) {
  // v1 and v2 are strings like '268.32 KB'
  const bytes1 = convertToBytes(v1);
  const bytes2 = convertToBytes(v2);

  return bytes1 - bytes2;
}

function S3ManagerV1() {
  const s3 = new AWS.S3();
  const [searchQuery, setSearchQuery] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [breadCrumpsPath, setBreadCrumpsPath] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [s3BucketName, setS3BucketName] = useState("");
  const [s3Path, setS3Path] = useState("");
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clickedFolderPath, setClickedFolderPath] = useState("");
  const [clickInProgress, setClickInProgress] = useState(false);
  const [downloadPopover, setDownloadPopover] = useState(false);
  const location = useLocation();
  const pathnames = location.pathname?.split("/").filter((x) => x);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      checkboxSelection: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Name",
      headerName: "File Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const isFolder = params.row.fileType === "folder";

        const handleClick = () => {
          setClickInProgress(true);
          if (isFolder) {
            // Check if a click is in progress
            if (clickInProgress) {
              return; // If a click is already in progress, do nothing
            }
            // Set the click in progress

            const lastObject = breadCrumpsPath[breadCrumpsPath.length - 1];
            if (lastObject.title !== params.row.Name) {
              let pathObj = {
                title: params.row.Name,
                onClick: () => {
                  fetchS3ObjectsBC(params.row.Key, params.row.Name);
                },
              };

              setBreadCrumpsPath((prevItems) => [...prevItems, pathObj]);
              setClickedFolderPath(params.row.Key);
              fetchS3Objects(params.row.Key);
            }

            // Reset the click in progress after 2 seconds
            setTimeout(() => {
              setClickInProgress(false);
            }, 2000); // 2000 milliseconds = 2 seconds
          } else {
            // Handle other file types click if needed
            // Reset the click in progress after 2 seconds
            setTimeout(() => {
              setClickInProgress(false);
            }, 2000); // 2000 milliseconds = 2 seconds
          }
        };

        return (
          <div
            className={
              isFolder ? "clickable-folder-yes" : "clickable-folder-no"
            }
            onClick={handleClick}
          >
            {params.row.fileType === "folder" ? (
              <img src={FolderIcon} alt="Menu Config" className="s3-bg-color" />
            ) : (
              <img src={FileIcon} alt="Menu Config" className="s3-bg-color" />
            )}
            <span>{params.row.Name}</span>
          </div>
        );
      },
    },
    {
      field: "fileType",
      headerName: "File Type",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "size",
      headerName: "Size",
      flex: 1,
      sortComparator: sizeComparator,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "lastModified",
      headerName: "Last Modified",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
  ];

  const handleFileSelected = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleClickOpen = () => {
    setOpenUpload(true);
  };

  const handleClickClose = () => {
    setSelectedFile(null);
    setOpenUpload(false);
  };

  const handleDownloadPopoverClose = () => {
    setDownloadPopover(false);
  };

  const handleDownloadPopoverConfirm = () => {
    handleDownload();
    setDownloadPopover(false);
  };

  useEffect(() => {
    const s3Path =
      pathnames[0] === routeName
        ? biUserData?.S3?.S3Path
        : userData?.S3?.S3Path;

    var s3pathOnly = s3Path?.split("/");

    var reqPath = s3pathOnly === undefined ? "" : s3pathOnly[1];
    setS3Path(`${reqPath}`);
    setS3BucketName(s3pathOnly !== undefined ? s3pathOnly[0] : "");
    getAwsDetails();
    fetchS3ObjectsBC(`${reqPath}`, "home");

    let pathObj = {
      title: reqPath,
      onClick: () => {
        fetchS3ObjectsBC(`${reqPath}`, "home");
      },
    };

    let tempArr = [];
    tempArr.push(pathObj);
    setBreadCrumpsPath(tempArr);
  }, []);

  function getAwsDetails() {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
      // "AnotherHeader": "Value"
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/aws/awsDetails`, {
        headers: headers,
      })
      .then((response) => {
        // Handle the response data here
        // setFormData({
        //   awsAccountId: response.data.data.AwsAccountId,
        //   awsCognitoId: response.data.data.CognitoUserPoolId,
        //   bucketName: response.data.data.AwsDetailsBucketName,
        //   awsRegion: response.data.data.AwsDetailsRegion,
        //   awsAccessKey: response.data.data.AwsDetailsAccessKeyId,
        // });
        AWS.config.update({
          accessKeyId: response.data.data.AwsDetailsAccessKeyId,
          secretAccessKey: response.data.data.AwsDetailsSecretAccessKey,
          region: response.data.data.AwsDetailsRegion,
        });
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
      });
  }

  const fetchS3ObjectsBC = (path, name) => {
    if (name === "home") {
      fetchS3Objects(`${path}/`);
      let pathObj = {
        title: path,
        onClick: () => {
          fetchS3ObjectsBC(`${path}`, "home");
        },
      };

      let tempArr = [];
      tempArr.push(pathObj);
      setBreadCrumpsPath(tempArr);
      setClickedFolderPath(`${path}/`);
    } else {
      /**
       * !Check error regarding Bread crumps here
       */

      const lastObject = breadCrumpsPath[breadCrumpsPath.length - 1];
      if (lastObject.title !== name) {
        let pathObj = {
          title: name,
          onClick: () => {
            fetchS3ObjectsBC(path, name);
          },
        };

        let tempArr = breadCrumpsPath;
        tempArr.push(pathObj);
        setBreadCrumpsPath(tempArr);
        setClickedFolderPath(`${path}/`);

        fetchS3Objects(path);
      }
    }
  };

  const fetchS3Objects = (path) => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const reqBody = {
      path: path,
    };
    postApi(
      "/s3/listBucket",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        // console.log(res.data.files);
        const rowsTemp = res.data?.files?.map((file, index) => ({
          id: `file-${index}`,
          Key: file.Key,
          Name: file.Name,
          fileType: file.Type,
          size: formatSize(file.Size),
          lastModified: file.LastModified,
        }));

        res.data?.folders?.forEach((folder, index) => {
          rowsTemp.push({
            id: `folder-${index}`,
            Key: folder.Key,
            Name: folder.Name,
            fileType: folder.Type,
            size: "-",
            lastModified: "-",
          });
        });

        setRows(rowsTemp);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const handleDownloadClick = async () => {
    setDownloadPopover(true);
  };

  const handleDownload = async () => {
    const bucketName = s3BucketName;
    if (selectedRows.length === 0) {
      toast("Please select a file", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
    }

    for (const row of selectedRows) {
      const params = {
        Bucket: bucketName,
        Key: row.Key,
      };

      try {
        const response = await s3.getObject(params).promise();
        const blob = new Blob([response.Body], { type: response.ContentType });
        const fileName = row?.Key?.split("/").pop();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      toast("No file selected", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
      return;
    }

    // Find the last '/' in the string

    var cfPath = clickedFolderPath;

    if (cfPath.endsWith("//")) {
      cfPath = cfPath.slice(0, -2);
    } else {
      let lastSlashIndex = clickedFolderPath.lastIndexOf("/");

      // Remove everything after the last '/', including the last '/'
      cfPath = clickedFolderPath.substring(0, lastSlashIndex);
    }

    const uploadParams = {
      Bucket: s3BucketName,
      Key:
        clickedFolderPath === ""
          ? `${s3Path}/${selectedFile.name}`
          : `${cfPath}/${selectedFile.name}`,
      Body: selectedFile,
    };

    try {
      await s3.upload(uploadParams).promise();

      // fetchS3Objects(`${s3Path}`, "Home", "NBC");
      var fetchPath = "";
      if (clickedFolderPath.endsWith("//")) {
        fetchPath = `${cfPath}/`;
      } else {
        fetchPath = clickedFolderPath;
      }
      fetchS3Objects(fetchPath, selectedFile.name);
      handleClickClose();
      toast("File uploaded successfully", {
        type: "success",
        theme: "dark",
        autoClose: 2000,
      });
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const filterMenuList = () => {
    return rows.filter((item) => {
      return (
        // firstElementOfObjectKey === s3Path && // Compare with s3Path
        item?.Key?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.fileType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.size?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.lastModified?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  const formatSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
      return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  const getFileType = (fileName) => {
    const extension = fileName?.split(".").pop();
    // You can add more extensions and their corresponding types if needed
    const fileTypeMap = {
      pdf: "PDF",
      doc: "Document",
      docx: "Document",
      xls: "Spreadsheet",
      xlsx: "Spreadsheet",
      jpg: "Image",
      png: "Image",
      txt: "Text",
      // Add more extensions and types as needed
    };
    return fileTypeMap[extension.toLowerCase()] || "folder";
  };

  function convertISOToUserFriendlyDate(isoString) {
    const date = new Date(isoString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };

    return date.toLocaleString(undefined, options);
  }

  return (
    <div>
      {pathnames[0] === "bi" ? "" : <Navbar />}
      {pathnames[0] === "bi" ? "" : <NavbarInner />}
      <div style={{ padding: "1%" }} className="bgProps">
        <Paper elevation={1} sx={{ borderRadius: "5px" }}>
          <Box
            sx={{
              p: 3,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <Typography variant="h5" className="s3-subheader">
              S3 File Manager
            </Typography>

            <Breadcrumb items={breadCrumpsPath} className="breadcrumb-item" />
            <div className="s3-header">
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  size="medium"
                  variant="outlined"
                  startIcon={<CloudDownloadOutlinedIcon />}
                  onClick={handleDownloadClick}
                  sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
                >
                  Download
                </Button>
                <Button
                  size="medium"
                  variant="outlined"
                  startIcon={<CloudUploadOutlinedIcon />}
                  onClick={handleClickOpen}
                  sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
                >
                  Upload
                </Button>
              </div>

              <TextField
                id="outlined-basic"
                label="Search"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <DataGrid
              rows={filterMenuList()}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                ...rows.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              pageSizeOptions={[10, 25]}
              disableRowSelectionOnClick
              isRowSelectable={(params) => params.row.fileType !== "folder"}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = rows.filter((row) =>
                  selectedIDs.has(row.id.toString())
                );
                setSelectedRows(selectedRowData);
              }}
            />
          </Box>
        </Paper>
        <Dialog
          open={openUpload}
          onClose={handleClickClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          PaperProps={{
            sx: {
              width: "50vw", // Set the desired width, e.g., 80% of the screen width

              borderRadius: "5px", // Add border radius if needed
            },
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>{"Upload  file "}</DialogTitle>
            <DialogTitle>
              <CloseIcon onClick={handleClickClose} className="pointerCursor" />
            </DialogTitle>
          </div>
          <DialogContent>
            <DialogContentText>{"Select a file to upload"}</DialogContentText>
            <div className="file-input-container">
              <label className="custom-file-input">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileSelected}
                />{" "}
                Choose File
              </label>
              {selectedFile && (
                <div className="selected-file-details">
                  <p>Selected File: {selectedFile.name}</p>
                  <p>File Size: {Math.round(selectedFile.size / 1024)} KB</p>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: whiteColor,
                color: primaryColor1,
                borderColor: primaryColor1,
              }}
              onClick={handleFileUpload}
            >
              Upload
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: primaryColor1 }}
              onClick={handleClickClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
        <Dialog
          open={downloadPopover}
          onClose={handleDownloadPopoverClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          PaperProps={{
            sx: {
              width: "50vw", // Set the desired width, e.g., 80% of the screen width
              borderRadius: "5px", // Add border radius if needed
            },
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>{"Are you sure you want to download ?"}</DialogTitle>
            <DialogTitle>
              <CloseIcon
                onClick={handleDownloadPopoverClose}
                className="pointerCursor"
              />
            </DialogTitle>
          </div>
          <DialogContent>
            <DialogContentText>
              <div>
                <p>
                  File Download will take several minutes to complete, depending
                  upon the file size.
                </p>
                <p>
                  INFO - Navigating from this page may interrupt the process.
                </p>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 3,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: whiteColor,
                color: primaryColor1,
                borderColor: primaryColor1,
              }}
              onClick={handleDownloadPopoverConfirm}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: primaryColor1 }}
              onClick={handleDownloadPopoverClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default S3ManagerV1;
