import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { savePath } from "../AdminSlice";
import "./breadcrumbs.scss";

function Breadcrumb() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const dispatch = useDispatch();

  function toPascalCase(str) {
    if (str !== "") {
      return str.replace(/(\w)(\w*)/g, function (_, firstChar, rest) {
        return firstChar.toUpperCase() + rest.toLowerCase();
      });
    }
  }

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      sx={{
        fontSize: { xs: "10px", sm: "12px", md: "14px" },
        mb: 1,
        flexWrap: "wrap",
      }}
    >
      {pathnames.map((name, index) => {
        var routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        if (routeTo === "/admin") {
          // routeTo = "/admin/row-level-security";
        }
        dispatch(savePath(routeTo));
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography
            key={name}
            sx={{
              fontSize: "inherit",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {toPascalCase(name)}
          </Typography>
        ) : (
          <Link
            key={name}
            to={routeTo}
            style={{
              textDecoration: "none",
              fontSize: "inherit",
              display: "inline-flex",
              alignItems: "center",
              gap: "2px",
            }}
            className="breadcrumb-link"
          >
            {toPascalCase(name)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}

export default Breadcrumb;
