import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectBiLogin, currentUserData } from "../user/LoginSlice";
import { primaryColor1 } from "../commonStyles/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { routeName } from "../commonStrings/CommonStrings";
import { postApi } from "..//api/Api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "./loader.scss";

function EditManageUser({ editOpen, editClose, editList, tableRefresh }) {
  const [formData, setFormData] = useState({
    userName: editList.UserName,
    name: "",
    password: "",
    userStatus: "",
    s3Path: "",
    emailId: "",
    role: "",
    cqwik: "",
    s3Status: false,
  });
  const [buttonLoader, setButtonLoader] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const [showPassword, setShowPassword] = useState(false);

  const CqwikRoleList = [
    { label: "Admin", value: 1 },
    { label: "User", value: 2 },
  ];
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      userName: editList.CognitoUserName,
      name: editList?.Name ? editList?.Name : "",
      // emailId: "",
      password: "",
      userStatus: "",
      s3Path: editList?.S3Path ? editList?.S3Path : "",
      emailId: editList.Email,
      role: editList.CognitoUserRole,
      cqwik: editList.CqwikRole,
      s3Status: editList?.S3Status === true ? true : false,
    }));

    setToggleStatus(editList?.S3Status === true ? true : false);
  }, [editList]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleToggleS3 = (e) => {
    setToggleStatus(!toggleStatus);
  };

  const onClose = () => {
    setFormData({
      userName: "",
      name: "",
      // emailId: "",
      password: "",
      userStatus: "",
      s3Path: "",
      emailId: "",
      role: "",
      cqwik: "",
      s3Status: false,
    });
    editClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoader(true);
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId;

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
    };
    const reqBody = {
      userName: editList.CognitoUserName,
      role: formData.role === "" ? editList.CognitoUserRole : formData.role,
      cqwik: formData.cqwik === "" ? editList.CqwikRole : formData.cqwik,
      email: formData.emailId === "" ? editList.Email : formData.emailId,
      s3status: toggleStatus,
      s3path: formData.s3Path === "" ? editList.S3Path3path : formData.s3Path,
      name: formData.name,
      password: formData.password,
    };

    postApi(
      "/quickSight/updateUserRole",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        if (res?.status === true) {
          toast("User Edited Successfully", {
            type: "success",
            theme: "dark",
            autoClose: 2000,
          });
          tableRefresh();
          editClose();
          setToggleStatus(false);
        } else if (res?.data?.status === false) {
          toast(res?.data?.message, {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        } else {
          toast(res?.message, {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        }
        setButtonLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        toast("Some error occured", {
          type: "error",
          theme: "dark",
          autoClose: 2000,
        });
        setButtonLoader(false);
      });
  };

  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/quickSight/getRoles`
        );
        const result = await response.json();
        if (result.status) {
          const transformedRoles = result.data.RoleList.map((role) => ({
            label:
              role.RoleName.charAt(0).toUpperCase() + role.RoleName.slice(1),
            value: role.RoleId,
          }));
          setRoleList(transformedRoles);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  return (
    <Dialog
      open={editOpen}
      onClose={onClose}
      maxWidth="md"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Edit User Details</DialogTitle>
        <DialogTitle>
          <CloseIcon onClick={editClose} className="pointerCursor" />
        </DialogTitle>
      </div>
      <DialogContent
        sx={{
          minHeight: "200px", // Set a minimum height
          minWidth: "500px", // Set a minimum width
          p: 3,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                id="username"
                disabled
                label={<span>Username</span>}
                fullWidth
                variant="outlined"
                type="text"
                name="userName"
                value={formData.userName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                id="name"
                label={<span>Name</span>}
                fullWidth
                variant="outlined"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                id="password"
                label={<span>Password</span>}
                fullWidth
                variant="outlined"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                margin="dense"
                id="email"
                label={<span>Email</span>}
                fullWidth
                variant="outlined"
                type="text"
                name="emailId"
                value={formData.emailId}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="role-label">QuickSight Role</InputLabel>
                <Select
                  labelId="role"
                  id="role"
                  value={`${formData?.role}`}
                  label="QuickSight Role"
                  name="role"
                  onChange={handleInputChange}
                >
                  {roleList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                size="small"
                margin="dense"
                fullWidth
              >
                <InputLabel id="cqwik-label">Cqwik Role</InputLabel>
                <Select
                  labelId="cqwik"
                  id="cqwik"
                  value={`${formData?.cqwik}`}
                  label="Cqwik Role"
                  name="cqwik"
                  onChange={handleInputChange}
                >
                  {CqwikRoleList.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <TextField
                  disabled={toggleStatus ? false : true}
                  size="small"
                  margin="dense"
                  id="s3Path"
                  label="S3 Path"
                  fullWidth
                  variant="outlined"
                  type="text"
                  name="s3Path"
                  value={formData.s3Path}
                  onChange={handleInputChange}
                />
                <div className="toggle-switch">
                  <input
                    className="toggle-input"
                    id="toggle"
                    type="checkbox"
                    checked={toggleStatus}
                    onChange={handleToggleS3}
                  />
                  <label className="toggle-label" htmlFor="toggle" />
                </div>
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
        }}
      >
        {buttonLoader ? (
          <div className="loader"></div>
        ) : (
          <>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ backgroundColor: primaryColor1 }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: primaryColor1 }}
              onClick={editClose}
            >
              Cancel
            </Button>
          </>
        )}
      </DialogActions>
      <ToastContainer />
    </Dialog>
  );
}
export default EditManageUser;
