import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Box,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./loader.scss";

import { DataGrid } from "@mui/x-data-grid";
import {
  buttonColorCommon,
  primaryColor1,
  whiteColor,
} from "../commonStyles/styles";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { routeName } from "../commonStrings/CommonStrings";
import { currentUserData, selectBiLogin } from "../user/LoginSlice";
import { postApi, deleteApi } from "../api/Api";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import "./admin.scss";
import DeleteIcon from "../../assets/deleteTable.png";
import { jsonTransform } from "./helpers/UserListHelper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { jsonTransformGroupUserList } from "./helpers/AddGroupUserHelper";
import { currentUserList } from "./AdminSlice";

function AddGroupUser(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [userList, setUserList] = useState([]);
  const [groupUserList, setGroupUserList] = useState([]);

  const [selectedUserName, setSelectedUserName] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderDelete, setLoaderDelete] = useState(false);
  const [loaderSave, setLoaderSave] = useState(false);

  const userListG = useSelector(currentUserList);
  const columns = [
    {
      field: "CognitoUserName",
      headerName: "Member Name",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Member Name"}</strong>,
    },

    {
      field: "actions",
      headerName: "Actions",

      disableColumnMenu: true,
      sortable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: () => <strong>{"Actions"}</strong>,
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              gap: "10px",
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete"
              className="bg-color pointerCursor"
              onClick={() => handleDelete(row)}
            />
          </div>
        );
      },
    },
  ];

  const handleDelete = useCallback((row) => {
    setDeleteId(row?.CognitoUserName);
    handleClickDeleteOpen();
  }, []);

  useEffect(() => {
    setLoader(true);
    getGroupUserList();
  }, []);

  const getGroupUserList = () => {
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const reqBody = {
      groupName: props.groupId,
    };
    postApi(
      "/quickSight/groupUserList",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((res) => {
        const transformArr = jsonTransformGroupUserList(
          res.data.GroupMemberList
        );

        setGroupUserList(transformArr);
        setLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        setLoader(false);
      });

    const newRes = jsonTransform(userListG);
    const transformedAdditionalArray = groupUserList.map(
      (item) => item.MemberName
    );
    const filteredTransformedUserArray = newRes.filter(
      (user) => !transformedAdditionalArray.includes(user.label)
    );
    setUserList(filteredTransformedUserArray);
  };
  const handleClickDeleteOpen = () => {
    setOpenDelete(true);
  };
  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };
  const filterMenuList = () => {
    return groupUserList.filter(
      (item) =>
        item?.MemberName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.Email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.CognitoUserName?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserAdd = (event, newValue) => {
    setSelectedUser(newValue);
    setSelectedUserName(newValue?.value);
  };

  const handleAddUsertoGroup = (e) => {
    e.preventDefault();
    setLoaderSave(true);
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };

    const reqBody = {
      groupName: props.groupId,
      memberUserName: selectedUserName,
    };

    if (
      selectedUserName === undefined ||
      selectedUserName === "" ||
      selectedUserName === null
    ) {
      toast("Please add a user", {
        type: "error",
        theme: "dark",
        autoClose: 2000,
      });
      setLoaderSave(false);
    } else {
      postApi(
        "/quickSight/addUserToGroup",
        reqBody,
        accessToken.AccessToken,
        organizationId,
        headers
      )
        .then((res) => {
          /**
           * ! add toast and close the popup
           */
          getGroupUserList();
          setLoaderSave(false);
          setSelectedUser({
            label: "Select a user",
            value: "",
          });
          setSelectedUserName("");
          handleClose();

          toast("Added user to group successfully", {
            type: "success",
            theme: "dark",
            autoClose: 2000,
          });
        })
        .catch((error) => {
          // console.log(error);
          setLoaderSave(false);
        });
    }
  };

  const handleSubmit = (e) => {
    setLoaderDelete(true);
    e.preventDefault();
    const accessToken =
      pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys; // Replace with your actual access token
    const organizationId =
      pathnames[0] === routeName
        ? biUserData?.Organization.OrganizationId
        : userData?.Organization.OrganizationId; // Replace with your actual OrganizationId

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken.AccessToken}`,
      organizationId: organizationId,
      // Add more headers if needed
    };
    const reqBody = {
      groupName: props.groupId,
      memberUserName: deleteId,
    };

    deleteApi(
      "/quickSight/deleteUserFromGroup",
      reqBody,
      accessToken.AccessToken,
      organizationId,
      headers
    )
      .then((response) => {
        getGroupUserList();

        handleClickDeleteClose();

        setLoaderDelete(false);
        response.data.Status === 200
          ? toast(" Removed user from group successfully", {
              type: "success",
              theme: "dark",
              autoClose: 2000,
            })
          : toast("Some error occured, Please try again", {
              type: "error",
              theme: "dark",
              autoClose: 2000,
            });
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error("Error:", error);
        setLoaderDelete(false);
      });
  };
  const Loader = () => {
    return (
      <div className="spinner-outq">
        <div className="spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  };

  const filterUserListDropdown = () => {
    const filteredUserObjects = userList.filter((user) => {
      return !groupUserList.some(
        (group) => group.CognitoUserName === user.label
      );
    });

    const selectUserOption = { label: "Select a user", value: "" };
    return [selectUserOption, ...filteredUserObjects];
  };
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ArrowBackOutlinedIcon
          onClick={props.updateParentState}
          style={{ cursor: "pointer" }}
        />
        <Typography variant="h6" sx={{ p: 1 }}>
          Manage Group
        </Typography>
      </div>

      <div
        className="top-header"
        style={{ display: "flex", alignItems: "center" }}
      >
        <Typography sx={{ padding: "0% 0% 0% 1%" }}>
          {props.groupId}
          <br />
          {props.desc}
        </Typography>

        <div>
          <Button
            variant="outlined"
            endIcon={
              open ? (
                <KeyboardArrowUpOutlinedIcon />
              ) : (
                <KeyboardArrowDownOutlinedIcon />
              )
            }
            onClick={handleOpen}
            sx={{ backgroundColor: whiteColor, color: buttonColorCommon, m: 1 }}
          >
            Add User
          </Button>
          <TextField
            id="outlined-basic"
            sx={{ m: 1 }}
            label="Search User"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          PaperProps={{
            sx: {
              width: "50vw", // Set the desired width, e.g., 80% of the screen width
              height: "40%", // Set the desired height, e.g., 80% of the screen height
              borderRadius: "5px", // Add border radius if needed
            },
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle>Add User to Group</DialogTitle>
            <DialogTitle>
              <CloseIcon onClick={handleClose} className="pointerCursor" />
            </DialogTitle>
          </div>
          <DialogContent>
            <Box sx={{ paddingBottom: "20px" }}></Box>

            <div>
              <Autocomplete
                options={filterUserListDropdown()}
                getOptionLabel={(option) => option.label}
                value={selectedUser}
                onChange={handleUserAdd}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search User"
                    variant="outlined"
                    size="medium"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                    }}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 3,
            }}
          >
            {loaderSave ? (
              <div className="loader"></div>
            ) : (
              <>
                <Button
                  variant="contained"
                  onClick={handleAddUsertoGroup}
                  sx={{ backgroundColor: primaryColor1 }}
                >
                  Add User
                </Button>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: primaryColor1 }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </div>

      {loader ? (
        <Loader />
      ) : groupUserList?.length !== 0 ? (
        <DataGrid
          rows={filterMenuList()}
          columns={columns}
          getRowId={(row) => row.MemberName}
          initialState={{
            ...groupUserList.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
            columns: {
              ...groupUserList.initialState?.columns,
            },
          }}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
        />
      ) : (
        <div>No users in group</div>
      )}
      <Dialog
        open={openDelete}
        onClose={handleClickDeleteClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle> {"Delete Group User"}</DialogTitle>
          <DialogTitle>
            <CloseIcon
              onClick={handleClickDeleteClose}
              className="pointerCursor"
            />
          </DialogTitle>
        </div>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete`} <span>{deleteId}</span> {"?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          {" "}
          {loaderDelete ? (
            <div className="loader"></div>
          ) : (
            <>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: whiteColor,
                  color: primaryColor1,
                  borderColor: primaryColor1,
                }}
                onClick={handleSubmit}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                onClick={handleClickDeleteClose}
                sx={{ backgroundColor: primaryColor1 }}
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}

export default AddGroupUser;
