import ManageGroup from "./ManageGroup";
import ManageUser from "./ManageUser";
import GroupPermissions from "./RoleLevelSecuruty/GroupPermissions";
import UserPermissionV1 from "./RoleLevelSecuruty/UserPermissionV1";
import RowLevelSecurity from "./RowLevelSecurity/RowLevelSecurity";
import AwsConfiguration from "./AwsConfiguration";

import "./admin.scss";
import { ChevronRightOutlined } from "@mui/icons-material";

import MenuConfigO from "../../assets/MenuConfigO.png";
import ManageUserO from "../../assets/manageUserO.png";
import ManageGroupO from "../../assets/manageGroupO.png";
import AwsConfigO from "../../assets/awsConfigO.png";
import RoleLevelO from "../../assets/roleLevelO.png";
import RowLevelO from "../../assets/rowLevelO.png";
import MenuConfig from "./menuConiguration/MenuTree";

export const SideBarData = [
  {
    title: "Manage User",
    path: "manage-user",
    element: <ManageUser />,
    icon: <img src={ManageUserO} alt="Menu Config" className="bg-color" />,
    subItems: [],
  },
  {
    title: "Manage Group",
    path: "manage-group",
    element: <ManageGroup />,
    icon: <img src={ManageGroupO} alt="Menu Config" className="bg-color" />,
    subItems: [],
  },
  {
    title: "AWS Configuration",
    path: "aws-configuration",
    element: <AwsConfiguration />,
    icon: <img src={AwsConfigO} alt="Menu Config" className="bg-color" />,
    subItems: [],
  },
  {
    title: "Menu Configuration",
    path: "menu-configuration",
    element: <MenuConfig />,
    icon: <img src={MenuConfigO} alt="Menu Config" className="bg-color" />,
    subItems: [],
  },
  {
    title: "Role Level Security",
    path: "role-level-security",
    // element: <RoleLevelSecurity />,
    icon: <img src={RoleLevelO} alt="Menu Config" className="bg-color" />,
    subItemIcon: <ChevronRightOutlined className="bg-color" />,
    subItems: [
      {
        name: "User Permissions",
        path: "role-level-security/user-permissions",
        element: <UserPermissionV1 />,
      },
      {
        name: "Group Permissions",
        path: "role-level-security/group-permissions",
        element: <GroupPermissions />,
      },
    ],
  },
  {
    title: "Row Level Security",
    path: "row-level-security",
    element: <RowLevelSecurity />,
    icon: <img src={RowLevelO} alt="Menu Config" className="bg-color" />,
    subItems: [],
  },
];
