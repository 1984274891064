import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { currentUserData, selectBiLogin } from "../user/LoginSlice";
import { routeName } from "../commonStrings/CommonStrings";

function Code(props) {
  const [customerCodes, setCustomerCodes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(
    localStorage.getItem("region") || ""
  );

  const biUserData = useSelector(selectBiLogin);
  const userData = useSelector(currentUserData);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  useEffect(() => {
    fetchCustomerCodes();
  }, []);

  const fetchCustomerCodes = async () => {
    try {
      const accessToken =
        pathnames[0] === routeName ? biUserData?.AuthKeys : userData?.AuthKeys;
      const organizationId =
        pathnames[0] === routeName
          ? biUserData?.Organization?.OrganizationId
          : userData?.Organization?.OrganizationId;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken?.AccessToken}`,
        organizationId: organizationId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/customerCode/lists`,
        { headers: headers }
      );
      const data = response.data;
      if (data.status) {
        setCustomerCodes(data.data);
      } else {
        console.error("Error fetching customer codes:", data.message);
      }
    } catch (error) {
      console.error("Error fetching customer codes:", error);
    }
  };

  const handleRegionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedRegion(selectedValue);
    const selectedCustomerToken = customerCodes.find(
      (code) => code.customer_code === selectedValue
    );

    if (selectedCustomerToken) {
      const customerToken = {
        CustomerCode: selectedCustomerToken.customer_code,
        ParamCustomerCode: selectedCustomerToken.paramcustomercode,
        ParamL1Label: selectedCustomerToken.paraml1label,
        ParamL2Label: selectedCustomerToken.paraml2label,
        ParamL3Label: selectedCustomerToken.paraml3label,
        ParamL4Label: selectedCustomerToken.paraml4label,
        ParamL5Label: selectedCustomerToken.paraml5label,
      };

      localStorage.setItem("customerToken", JSON.stringify(customerToken));      
    }

    localStorage.setItem("region", selectedValue);
    window.location.reload();
  };

  return (
    <>
    {customerCodes.length > 0 && (
    <FormControl
      sx={{ m: 1, minWidth: 200, border: "none" }}
      style={{ border: "none", position: "absolute", right: "0" }}
    >
      <InputLabel id="region-label">Customer Name</InputLabel>
      
        <Select
          labelId="region-label"
          id="region-select"
          value={selectedRegion}
          onChange={handleRegionChange}
          variant="standard"
        >
          {customerCodes.map((code) => (
            <MenuItem key={code.customer_code} value={code.customer_code}>
              {code.customer_name}
            </MenuItem>
          ))}
        </Select>
    </FormControl>
    )}
    </>
  );
}

export default Code;