import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./navbarInner.scss";
import { whiteColor } from "../commonStyles/styles";
import {
  links,
  linksAdmin,
  linksAdminBi,
  linksAdminBiNonS3,
  linksAdminNonS3,
  linksBi,
  linksBiNonS3,
  linksNonS3,
} from "./NavbarData";
import { selectBiLogin, selectUser, currentUserData } from "../user/LoginSlice";
import { currentPath } from "../admin/AdminSlice";
import { routeName } from "../commonStrings/CommonStrings";
import Code from "../code/code";

function NavbarInner(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const location = useLocation();
  const [navArr, setNavArr] = useState([]);
  const [regionValue, setRegionValue] = useState("");
  const user = useSelector(selectUser);
  const biUser = useSelector(selectBiLogin);
  const currentUserDataAll = useSelector(currentUserData);
  const routePath = useSelector(currentPath);

  const navigate = useNavigate();

  const pathnames = location.pathname.split("/").filter((x) => x);

  function isEmptyObject(obj) {
    if (obj !== undefined) {
      return Object.keys(obj).length === 0 || obj.S3Path === "";
    }
  }

  useEffect(() => {
    let tempArray = [];

    pathnames[0] === routeName
      ? biUser?.CqwikRole === 1
        ? isEmptyObject(biUser?.S3)
          ? (tempArray = linksAdminBiNonS3)
          : (tempArray = linksAdminBi)
        : isEmptyObject(biUser?.S3)
        ? (tempArray = linksBiNonS3)
        : (tempArray = linksBi)
      : user === "admin"
      ? isEmptyObject(currentUserDataAll?.S3)
        ? (tempArray = linksAdminNonS3)
        : (tempArray = linksAdmin)
      : isEmptyObject(currentUserDataAll?.S3)
      ? (tempArray = linksNonS3)
      : (tempArray = links);

    setNavArr(tempArray);
    if (routePath !== "") {
    }
  }, [user, location.pathname, routePath]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);

    if (page === "Home") {
      navigate("/home");
    } else if (page === "Dashboard") {
      navigate("/analytics");
    }
  };

  const handleRegionChange = (event) => {
    setRegionValue(event.target.value);
  };
  return (
    <div>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: whiteColor, height: 60 }}
        elevation={0}
      >
        <div style={{ padding: "0% 1% 0% 1%" }}>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {navArr.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => handleCloseNavMenu(page.text)}
                    sx={{ color: "black" }}
                  >
                    <Link to={page.url} className="linkStyle">
                      {page.text}
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
              <Code />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              {navArr.map((page, index) => (
                <Button
                  key={page.id}
                  onClick={() => handleCloseNavMenu(page.text)}
                  sx={{
                    my: 2,
                    color: "black",
                    display: "block",
                    marginRight: "10px", // Adjust spacing between buttons if needed
                    whiteSpace: "nowrap", // Prevent text from wrapping
                    textOverflow: "ellipsis", // Show ellipsis if text exceeds available space
                    overflow: "hidden", // Hide any overflowing text
                  }}
                >
                  <Link
                    to={page.url}
                    className={`linkStyle${
                      location.pathname === page.url ||
                      (index === 0 && !location.pathname)
                        ? // ||
                          // hoveredLinkId === page.id
                          " active"
                        : ""
                    }`}
                  >
                    {page.text}
                  </Link>
                </Button>
              ))}
              <Code />
            </Box>
          </Toolbar>
        </div>
      </AppBar>
    </div>
  );
}

export default NavbarInner;
