import React, { useState, useEffect } from "react";
import { Button, Box, Paper, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { primaryColor1, whiteColor } from "../../commonStyles/styles";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import "../loader.scss";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteRowLevelSecurity from "./DeleteRowLevelSecurity";
import { nullHandlerHelper } from "../helpers/nullHandlerHelper";
import AddRowLevelSecurityV2 from "./AddRowLevelSecurityV2";
import { reloadPath } from "../AdminSlice";

function RowLevelSecurity() {
  const [searchQuery, setSearchQuery] = useState("");
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowLevelArr, setRowLevelArr] = useState([]);
  const [addRow, setAddRow] = useState(false);

  //   const [menuList, setMenuList] = useState([]);
  const currentReload = useSelector(reloadPath);

  const menuList = [
    {
      id: 1,
      UserName: "test",
      GroupName: "group",
      CustomerCode: "code",
      Email: "e@bz.ai",
      Role: "All",
    },
    {
      id: 2,
      UserName: "test2",
      GroupName: "group",
      CustomerCode: "code",
      Email: "e@bz.ai",
      Role: "Connect",
    },
    {
      id: 3,
      UserName: "test3",
      GroupName: "group",
      CustomerCode: "code",
      Email: "e@bz.ai",
      Role: "All",
    },
  ];

  const levelArr = [
    { value: "All", label: "All" },
    { value: "Connect", label: "Connect" },
  ];

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerClassName: "super-app-theme--header",

      hide: true,
      flex: 1,

      renderHeader: () => <strong>{"ID"}</strong>,
    },
    {
      field: "username",
      headerName: "Username",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Username"}</strong>,
    },
    {
      field: "groupname",
      headerName: "Group Name",
      headerClassName: "super-app-theme--header",

      flex: 1,
      renderHeader: () => <strong>{"Group Name"}</strong>,
    },
    {
      field: "customer_code",
      headerName: "Customer Code",
      headerClassName: "super-app-theme--header",
      width: 200,

      renderHeader: () => <strong>{"Customer Code"}</strong>,
    },
    {
      field: "level1",
      headerName: "Level 1",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 1"}</strong>,
    },

    {
      field: "level2",
      headerName: "Level 2",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 2"}</strong>,
    },
    {
      field: "level3",
      headerName: "Level 3",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 3"}</strong>,
    },
    {
      field: "level4",
      headerName: "Level 4",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 4"}</strong>,
    },

    {
      field: "level5",
      headerName: "Level 5",
      headerClassName: "super-app-theme--header",
      flex: 1,
      renderHeader: () => <strong>{"Level 5"}</strong>,
    },
  ];

  useEffect(() => {
    getTableDetails();
    setAddRow(false);
  }, [currentReload]);

  const refreshTable = () => {
    getTableDetails();
  };
  const getTableDetails = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/security/row/list`)
      .then((res) => {
        const newResVal = nullHandlerHelper(res?.data?.data);

        setRowLevelArr(newResVal);
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const handleClickAddOpen = () => {
    setAddRow(true);
  };

  const handleClickDeleteOpen = () => {
    setOpenDelete(true);
  };

  const handleClickDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleClickAddClose = () => {
    setOpenAdd(false);
  };
  const filterMenuList = () => {
    return rowLevelArr.filter(
      (item) =>
        item?.username?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.groupname?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.customer_code
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase()) ||
        item?.level1?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.level2?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.level3?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.level4?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
        item?.level5?.toLowerCase()?.includes(searchQuery.toLowerCase())
    );
  };

  const updateParentState = () => {
    setAddRow(false);
  };

  return (
    <div>
      {addRow ? (
        <Paper elevation={2} sx={{ borderRadius: "5px" }}>
          <Box
            sx={{
              p: 4,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <AddRowLevelSecurityV2
              refreshTable={refreshTable}
              updateParentState={updateParentState}
            />
          </Box>
        </Paper>
      ) : (
        <Paper elevation={1} sx={{ borderRadius: "5px" }}>
          <Box
            sx={{
              p: 4,
              alignItems: "center",
              "& .super-app-theme--header": {
                backgroundColor: primaryColor1,
                color: whiteColor,
              },
            }}
          >
            <Typography variant="h6" className="sub-header">
              Row Level Security
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: "2%",
              }}
            >
              <div>
                <Button
                  size="medium"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleClickAddOpen}
                  sx={{
                    backgroundColor: whiteColor,
                    color: primaryColor1,
                    marginRight: "10px",
                  }}
                >
                  Add Row Level Security
                </Button>

                <Button
                  size="medium"
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={handleClickDeleteOpen}
                  sx={{ backgroundColor: whiteColor, color: primaryColor1 }}
                >
                  Delete Row Level Security
                </Button>
              </div>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Search"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                  }}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <DataGrid
              rows={filterMenuList()}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                ...menuList.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              pageSizeOptions={[10, 25]}
              disableRowSelectionOnClick
            />

            <DeleteRowLevelSecurity
              openDelete={openDelete}
              onClose={handleClickDeleteClose}
              getTableDetails={getTableDetails}
              data={rowLevelArr}
              refreshTable={refreshTable}
            />
          </Box>
        </Paper>
      )}
    </div>
  );
}

export default RowLevelSecurity;
