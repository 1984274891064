import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import axios from "axios";
import { primaryColor1, whiteColor } from "../../commonStyles/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../loader.scss";

function DeleteRowLevelSecurity({ openDelete, onClose, data, refreshTable }) {
  const [groupNameArr, setGroupNameArr] = useState([]);
  const [userNameArr, setUserNameArr] = useState([]);
  const [customerCodeArr, setCustomerCodeArr] = useState([]);

  const [groupName, setGroupName] = useState("");
  const [userName, setUserName] = useState("");
  const [customerCode, setCustomerCode] = useState("");

  const [buttonLoader, setButtonLoader] = useState(false);

  const getGroupNames = () => {
    const uniqueGroupnames = new Set();

    data.forEach((item) => {
      if (item.groupname !== null) {
        uniqueGroupnames.add(item.groupname);
      }
    });

    const uniqueGroupnamesArray = Array.from(uniqueGroupnames).map(
      (username) => ({
        label: username,
        value: username,
      })
    );

    setGroupNameArr(uniqueGroupnamesArray);
  };

  const getUserNames = () => {
    const uniqueUsernames = new Set();

    data.forEach((item) => {
      if (item.username !== null) {
        uniqueUsernames.add(item.username);
      }
    });

    // Convert the Set to an array of objects with 'label' and 'value' properties
    const uniqueUsernamesArray = Array.from(uniqueUsernames).map(
      (username) => ({
        label: username,
        value: username,
      })
    );

    setUserNameArr(uniqueUsernamesArray);
  };

  const getCustomerCode = () => {
    const uniqueCustomerCodes = new Set();

    data.forEach((item) => {
      if (item.customer_code !== null) {
        uniqueCustomerCodes.add(item.customer_code);
      }
    });

    const uniqueCustomerCodeArray = Array.from(uniqueCustomerCodes).map(
      (customer_code) => ({
        label: customer_code,
        value: customer_code,
      })
    );

    setCustomerCodeArr(uniqueCustomerCodeArray);
  };
  useEffect(() => {
    getGroupNames();
    getUserNames();
    getCustomerCode();
  }, [data]);

  const handleSubmit = () => {
    setButtonLoader(true);
    var reqBody = {
      userName: userName === "" ? "null" : userName.value,
      groupName: groupName === "" ? "null" : groupName.value,
      customerCode: customerCode.value,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/security/row/delete`, reqBody)
      .then((res) => {
        if (res.data.message === "no record") {
          toast("No record found", {
            type: "error",
            theme: "dark",
            autoClose: 2000,
          });
        }
        // resetAll();
        else {
          toast("Deleted row level security successfully", {
            type: "success",
            theme: "dark",
            autoClose: 2000,
          });
          onClose();
          setGroupName("");
          setCustomerCode("");
          setUserName("");
        }

        refreshTable();

        setButtonLoader(false);
      })
      .catch((err) => {
        // console.log(err);
        toast("Some error occured", {
          type: "error",
          theme: "dark",
          autoClose: 2000,
        });
        setButtonLoader(false);
      });
  };

  const resetAll = () => {
    setGroupName("");
    setCustomerCode("");
    setUserName("");
  };
  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={onClose}
        maxWidth="xl" // Set the maximum width to medium
        fullWidth // Make the dialog take the full width of the viewport
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        PaperProps={{
          sx: {
            width: "50vw",
            // height: "40%", // Set the desired height, e.g., 80% of the screen height
            borderRadius: "5px", // Add border radius if needed
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Delete Row level security</DialogTitle>
          <DialogTitle>
            <CloseIcon onClick={onClose} className="pointerCursor" />
          </DialogTitle>
        </div>

        <DialogContent
          sx={{
            minHeight: "300px", // Set a minimum height
            minWidth: "200px", // Set a minimum width
            p: 3,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Autocomplete
              options={groupNameArr}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={groupName}
              name="groupName"
              onChange={(event, newValue) => {
                setGroupName(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullwidth
                  label="Search Group"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                  }}
                />
              )}
            />
            <div style={{ margin: "10px 0" }} />
            <Autocomplete
              options={userNameArr}
              getOptionLabel={(option) =>
                option
                  ? option.label === "null"
                    ? "No options"
                    : option.label
                  : ""
              }
              value={userName}
              name="userName"
              onChange={(event, newValue) => {
                setUserName(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullwidth
                  label="Search User"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                  }}
                />
              )}
            />
            <div style={{ margin: "10px 0" }} />
            <Autocomplete
              options={customerCodeArr}
              getOptionLabel={(option) => (option ? option.label : "")}
              value={customerCode}
              name="userName"
              onChange={(event, newValue) => {
                setCustomerCode(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullwidth
                  label="Search Customer code"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon style={{ opacity: 0.5 }} />,
                  }}
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 3,
          }}
        >
          {buttonLoader ? (
            <div className="loader"></div>
          ) : (
            <>
              <Button
                //   disabled={!isFormValid()}
                variant="contained"
                sx={{ backgroundColor: primaryColor1 }}
                onClick={handleSubmit}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: whiteColor,
                  color: primaryColor1,
                  borderColor: primaryColor1,
                }}
                onClick={resetAll}
              >
                Reset
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: primaryColor1 }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}

export default DeleteRowLevelSecurity;
