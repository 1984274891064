export const jsonTransformLevel1 = (levels) => {
  const uniqueValues = new Set();
  const transformedArray = [];

  levels.forEach((user) => {
    const value = user.member_hrchy_l1;

    if (!uniqueValues.has(value)) {
      transformedArray.push({
        label: value,
        value: value,
      });

      uniqueValues.add(value);
    }
  });

  return transformedArray;
};

export const jsonTransformLevel2 = (levels) => {
  const uniqueValues = new Set();
  const transformedArray = [];

  levels.forEach((user) => {
    const value = user.member_hrchy_l2;

    if (!uniqueValues.has(value)) {
      transformedArray.push({
        label: value,
        value: value,
      });

      uniqueValues.add(value);
    }
  });

  return transformedArray;
};

export const jsonTransformLevel3 = (levels) => {
  const uniqueValues = new Set();
  const transformedArray = [];

  levels.forEach((user) => {
    const value = user.member_hrchy_l3;

    if (!uniqueValues.has(value)) {
      transformedArray.push({
        label: value,
        value: value,
      });

      uniqueValues.add(value);
    }
  });

  return transformedArray; // Return the array with unique items
};

export const jsonTransformLevel4 = (levels) => {
  const uniqueValues = new Set();
  const transformedArray = [];

  levels.forEach((user) => {
    const value = user.member_hrchy_l4;

    if (!uniqueValues.has(value)) {
      transformedArray.push({
        label: value,
        value: value,
      });

      uniqueValues.add(value);
    }
  });

  return transformedArray; // Return the array with unique items
};

export const jsonTransformLevel5 = (levels) => {
  const uniqueValues = new Set();
  const transformedArray = [];

  levels.forEach((user) => {
    const value = user.member_hrchy_l5;

    if (!uniqueValues.has(value)) {
      transformedArray.push({
        label: value,
        value: value,
      });

      uniqueValues.add(value);
    }
  });

  return transformedArray; // Return the array with unique items
};
