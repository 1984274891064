import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./components/user/Login";
import UnknownRoutes from "./components/unknownRoutes/UnknownRoutes";
import Home from "./components/home/Home";
import Analytics1 from "./components/analytics/Analytics1";
import "./App.scss";
import S3ManagerV1 from "./components/s3/S3ManagerV1";
import Admin from "./components/admin/Admin";
import { useSelector } from "react-redux";
import { selectUser } from "./components/user/LoginSlice";

function App() {
  const userToken = useSelector(selectUser);

  /**
   * !API call to very user and protect routes
   */
  const isLoggedIn = () => {
    if (userToken === "admin" || userToken === "user") {
      return true;
    } else {
      return false;
    }
  };

  // Define the protected route component (route guard)
  const ProtectedRouteWrapper = ({ element }) => {
    if (isLoggedIn()) {
      return element;
    } else {
      // Redirect to login page if the user is not logged in
      return <Navigate to="/login" />;
    }
  };

  return (
    <div className="">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<UnknownRoutes />} />
          <Route
            path="/home"
            element={<ProtectedRouteWrapper element={<Home />} />}
          />
          <Route
            path="/analytics"
            element={<ProtectedRouteWrapper element={<Analytics1 />} />}
          />
          <Route path="/s3-file-manager" element={<S3ManagerV1 />} />
          <Route path="/admin/*" element={<Admin />} />
          ( /** * ! Exclusively for BI */)
          <Route path="/bi" element={<Navigate to="/bi/analytics" />} />
          <Route path="/bi/admin/*" element={<Admin />} />
          <Route path="/bi/analytics" element={<Analytics1 />} />
          <Route path="/bi/s3-file-manager" element={<S3ManagerV1 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
