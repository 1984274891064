import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery,
  Grid,
  Paper,
  ListItemIcon,
  Popover,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SideBarData } from "./SidebarData";
import {
  Link,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./admin.scss";
import Navbar from "../navbar/Navbar";
import NavbarInner from "../navbarInner/NavbarInner";
import { blackColor, itemHover } from "../commonStyles/styles";
import BreadcrumbNav from "./breadcrumbsNav/BreadcrumbsNav";
import { routeName } from "../commonStrings/CommonStrings";
import { setBiLogin } from "../user/LoginSlice";
import { reload, reloadPath } from "../admin/AdminSlice";
function Admin() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const [hoveredSubmenu, setHoveredSubmenu] = useState(null);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const [recValue, setRecValue] = useState(false);
  const [loaderFlag, setLoaderFlag] = useState(false);

  const dispatch = useDispatch();
  const currentReload = useSelector(reloadPath);

  const handleListItemClick = (index, text, event) => {
    if (text.subItems.length === 0) {
      dispatch(reload(!currentReload));
      setSelectedItem(index);
    } else {
      setSelectedItem(index);
      setAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    if (SideBarData.length > 0) {
      const initialPath = SideBarData[2].path;

      navigate(initialPath);
      setSelectedItem(2);
    }
  }, []);

  const handleSubmenuMouseEnter = (submenu) => {
    setHoveredSubmenu(submenu);
  };

  const handleSubmenuMouseLeave = () => {
    setHoveredSubmenu(null);
  };

  const handleLinkClick = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    const messageHandler = (event) => {
      if (event.data !== "request_data") {
        setRecValue(true);

        /**
         *
         * !API call for verification
         */

        if (event.data?.data.hasOwnProperty("UserId")) {
          var biReduxData = event.data?.data;

          dispatch(setBiLogin(biReduxData));

          setLoaderFlag(false);
        } else {
          dispatch(setBiLogin());
        }
      } else {
        pathnames[0] === "bi" ? setLoaderFlag(true) : setLoaderFlag(false);
      }
    };

    if (pathnames[0] === routeName) {
      if (recValue === false) {
        window.addEventListener("message", messageHandler);
        window.parent.postMessage("request_data", "*");
      }

      return () => {
        window.removeEventListener("message", messageHandler);
      };
    } else {
    }
  }, [recValue]);

  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <div>
      {pathnames[0] === "bi" ? "" : <Navbar />}
      {pathnames[0] === "bi" ? "" : <NavbarInner />}

      <div className="bgProps">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Box p={2}>
              <Paper
                elevation={1}
                sx={{ borderRadius: "5px", height: "101vh" }}
              >
                <div className="sidebar">
                  <List>
                    {SideBarData.map((text, index) => (
                      <ListItem
                        key={text.title}
                        component={Link}
                        to={text.subItems.length === 0 ? text.path : ""}
                        disablePadding
                        sx={{
                          display: "flex",
                          flexDirection: isMobile ? "row" : "column",
                          alignItems: "center",
                        }}
                      >
                        <ListItemButton
                          selected={selectedItem === index}
                          onClick={(event) =>
                            handleListItemClick(index, text, event)
                          }
                          sx={{
                            minHeight: 48,
                            px: 2.5,
                            width: "100%",
                            color: "black",
                            "&:hover": {
                              backgroundColor: itemHover,
                            },
                            borderRadius: "10px",
                            fontWeight:
                              selectedItem === index ? "600" : "normal",
                          }}
                        >
                          <ListItemIcon>{text.icon}</ListItemIcon>

                          <ListItemText
                            primary={text.title}
                            disableTypography={true}
                          />

                          <ListItemIcon>{text.subItemIcon}</ListItemIcon>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Paper>
            </Box>
          </Grid>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <List style={{ minWidth: "250px" }}>
              {SideBarData[selectedItem]?.subItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  style={{ textDecoration: "none", color: blackColor }}
                  onClick={handleLinkClick}
                >
                  <ListItem
                    key={item.name}
                    onMouseEnter={() => handleSubmenuMouseEnter(item.name)}
                    onMouseLeave={handleSubmenuMouseLeave}
                    style={{
                      backgroundColor:
                        item.name === hoveredSubmenu ? itemHover : "inherit",
                      borderRadius: "10px",
                      // color: blackColor,
                    }}
                  >
                    <ListItemText primary={item.name} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Popover>
          <Grid item xs={12} sm={12} md={8} lg={9}>
            <div style={{ padding: "2% 0 0 1%" }}>
              <BreadcrumbNav />
            </div>

            <Box
              component="main"
              sx={{ flexGrow: 1 }}
              style={{ padding: "10px 10px 10px 0px" }}
            >
              <Box component="main" sx={{ flexGrow: 1 }}>
                <Routes>
                  {SideBarData.map((item) =>
                    item.subItems.length === 0 ? (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.element}
                      />
                    ) : (
                      item.subItems.map((e) => (
                        <Route key={e.name} path={e.path} element={e.element} />
                      ))
                    )
                  )}
                </Routes>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Admin;
